import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

import useNavigate from "hooks/Navigate";
import apis from '../../services';
import CssBaseline from "@mui/material/CssBaseline";
import {AdduserForm, AddUserForm} from "../../components/forms";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNewSidebar from 'components/admin/AdminNewSidebar';
import { Header } from 'components';



const INITIAL_FORM_STATE = {
    name: "",
    nickName: "",
    phone:"",
    email:""
}

function AddUpdateUser() {
    var ABC = 1;

    const [userData, setUserData] = useState(INITIAL_FORM_STATE)
    const {id: userId} = useParams();
      const navigate = useNavigate();
      const [phone, setPhone] = useState(userData?.phone)
    
    const onChangeHandler = (e) => {
        var ABC = 234
        let {name,value}=e.target;
         setUserData((prevState) => ({...prevState, [name]: value}));
        };
        const onPhoneHandler = (value) => {
            setPhone(value);
            };
    const fetchUserDetails = async (userId) => {
        try {
            const res = await apis.getProfile(userId)
            if (res.data.status) {
                setUserData(res.data.user)
                setPhone(res.data?.user?.phone)
            }
        } catch (e) {
            console.error('ERROR in fetching user details', e);
        }
    };

    const onUpdateUserHandler = async (data) => {
        try {
            const res = await apis.updateUserProfile(userId ,data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                navigate("/admin/users")
                setUserData(INITIAL_FORM_STATE)
            }
        } catch (e) {
            console.error('ERROR on update user', e);
        }
    }

    useEffect(() => {
        (async () => {
            if (userId)
                await fetchUserDetails(userId);

        })()
    }, [userId]);

    const onSubmitHandler = async () => {
      onUpdateUserHandler(userData)
    }

    return (
        <Box className="dashboard-main">
            <AdminNewSidebar/>
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
                className="custom-scroll"
            >
                <Header/>
                <Container>
                <h3 className="section-title dark-text text-start mb-0">{userId ? 'Update' : 'Add'} User</h3>
                    <AddUserForm
                        formData={userData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={userId ? 'Update' : 'Add'}
                        setPhone={setPhone}
                        phone={phone}
                        onPhoneHandler={onPhoneHandler}
                    />
                </Container>
            </Box>
            </div>
        </Box>
    );
}

export default AddUpdateUser;
