import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import imageUrl from "utils/imageUrl";
import {
  logout,
  setTotalUnseenNotifications,
  setUserNotifications,
} from "../redux/slices/userSlice";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";
import cms from "services/cms";
import cmsImage from "utils/cmsImage";
import { useTranslation } from "react-i18next";
import useNavigate from "hooks/Navigate";
import Link from "hooks/Link";
import i18next from "i18next";
import apis from "services";
import { walletNull } from "redux/slices/walletSlicer";
import { MdNotifications } from "react-icons/md";
import UserNotification from "./UserNotification";
import PageLoader from "./PageLoader";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import CallToNews from "./common/CallToNews";

const MainHeader = ({ bg = false, showDropdown, setShowDropdown }) => {
  const location = useLocation();
  console.log("location:", location);
  const [isActive, setIsActive] = useState(false);



  const navigate = useNavigate();
  const { user, totalUnseenNotifications, notifications } = useSelector(
    (store) => store.user
  );
  const admin = useSelector((store) => store.admin);
  const dispatch = useDispatch();
  const [mobileShow, setMobileShow] = useState(false);

  
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);

  const [loader, setLoader] = useState(false);

  const { i18n, t } = useTranslation();

  const language = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("sp");
    }
  }, []);

  const logoutUser = async () => {
    const res = await apis.logoutUser();
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    navigate("/", { state: { lang: value } });
  };

  const [setting, setSetting] = useState({});
  const fetchSetting = async () => {
    const res = await cms.getSetting("header", "all");
    setSetting(res?.data?.data?.header);
  };
  useEffect(() => {
    (async () => {
      await fetchSetting();
    })();
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const getUserNotification = async () => {
    try {
      setLoader(true);
      const res = await apis.getUserNotification(user?.id);
      if (res?.data?.status) {
        dispatch(setUserNotifications(res?.data?.notifications));
        dispatch(setTotalUnseenNotifications(res?.data?.unSeenNotifications));
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (user) {
      getUserNotification();
    }
  }, [showDropdown, user]);

  const updateChartSize = () => {
    const isMobileSize = window.matchMedia('(max-width: 768px)').matches;
    console.log(isMobileSize,"isMobileSizeisMobileSizeisMobileSize")
    setIsMobile(isMobileSize);
   
  };
  useEffect(() => {
    updateChartSize();
    window.addEventListener('resize', updateChartSize);
    return () => {
      window.removeEventListener('resize', updateChartSize);
    };
  }, []);

  return (
    <>
      {/* <div className={`main-header ${bg && "bg"}`} style={{top : user?.isVerified && user?.profileCompleted > 0 ?  "24px" : "0"}}>
        <div className="header-left">
          <Link to={"/"}>
            <div className="main-logo">
              <img
                src={cmsImage(setting?.logo)}
                alt=""
                onError={(e) => (e.currentTarget.src = imageUrl("logo.webp"))}
              />
            </div>
          </Link>
          <div className="header-line"></div>
          <Link to={"/franchise"} className="search-icon">
            <AiOutlineSearch />
          </Link>
          <Link to={"/franchise"} className="main-header-link">
            {t("words.invest")}
          </Link>
          <Link to={"/about-us"} className="main-header-link">
            {t("words.about")}
          </Link>
          <Link to={"/contact-us"} className="main-header-link">
            {t("words.contact")}
          </Link>
        </div>
        <div className="button-group">

          {user &&
            <>
            {totalUnseenNotifications > 0 && <span className="not-top-icon" style={ language === "en" ? {"right":"372px"}:{}}>{totalUnseenNotifications}</span>}
              <MdNotifications onClick={toggleDropdown} size={30} className="m-2 white" />
              {showDropdown && user && (
                <div
                  className="notification-card"
                     >
                  <UserNotification loader={loader} notifications={notifications} unseenCount={totalUnseenNotifications} showDropdown={showDropdown} />
                </div>
              )}


            </>
          }
          <div className="language-btn">


            <button
              className={`${language === "en" ? "active-lng" : ""}`}
              onClick={() => handleLanguageChange("en")}
            >
              EN
            </button>
            <button
              className={`${language === "sp" ? "active-lng" : ""}`}
              onClick={() => handleLanguageChange("sp")}
            >
              ES
            </button>
          </div>
          {user ? (
            <>

              <button
                onClick={() => navigate("/user/dashboard")}
                className="lp-btn"
              >
                {t("buttons.dashboard")}
              </button>
            </>
          ) : admin?.adminUser ? (
            <>
              <button
                onClick={() => navigate("/admin/dashboard")}
                className="lp-btn"
              >
                {t("buttons.dashboard")}
              </button>
            </>
          ) : (
            <>
              {" "}
              <button
                onClick={() => navigate("/login")}

                className="simple-btn login"
              >
                {t("buttons.login")}
              </button>
              <button
                onClick={() => navigate("/registration")}
                className="lp-btn"
              >
                {t("buttons.signup")}
              </button>
            </>
          )}

          {user && (
            <>
              <Link to="/login">
                <button
                  onClick={() => {
                    dispatch(logout());
                    dispatch(walletNull());
                    logoutUser();
                  }}
                  className="lp-btn ms-2"
                >
                  {" "}
                  {t("buttons.logout")}
                </button>
              </Link>
            </>
          )}
        </div>
        {!mobileShow && (
          <>
             {user &&
            <div className="not-button">
            {totalUnseenNotifications > 0 && <span className="not-top-icon">{totalUnseenNotifications}</span>}
              <MdNotifications onClick={toggleDropdown} size={30} className="m-2 white" />
              {showDropdown && user && (
                <div
                  className="notification-card"
                >
                  <UserNotification loader={loader} notifications={notifications} unseenCount={totalUnseenNotifications} showDropdown={showDropdown} />
                </div>
              )}


            </div>
          }

            <button
              className="header-button ms-2"
              onClick={() => {
                setMobileShow(true);
              }}
            >
              <RxHamburgerMenu />
            </button>
           
         
          </>
        )}
        <nav className={`${mobileShow && "show"} mobile-header`}>
          {mobileShow && (
            <>
              <button
                className="header-button close"
                onClick={() => {
                  setMobileShow(false);
                }}
              >
                <AiOutlineClose />
              </button>
            </>
          )}
          <ul>
            <li>
              <Link to={"/franchise"} className="mobile-header-link">
                Invest
              </Link>
            </li>
            <li>
              <Link className="mobile-header-link">Learn</Link>
            </li>
            {user ? (
              <li>
                <button
                  onClick={() => navigate("/user/dashboard")}
                  className="lp-btn"
                >
                  {t("buttons.dashboard")}
                </button>
              </li>
            ) : admin?.adminUser ? (
              <li>
                <button
                  onClick={() => navigate("/admin/dashboard")}
                  className="lp-btn"
                >
                  {t("buttons.dashboard")}
                </button>
              </li>
            ) : (
              <>
                <li>
                  <button
                    onClick={() => navigate("/login")}
                    className="simple-btn login"
                  >
                    {t("buttons.login")}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate("/registration")}
                    className="lp-btn"
                  >
                    {t("buttons.signup")}
                  </button>
                </li>
              </>
            )}
            <li>
              <div className="language-btn">
                <button
                  className={`${language === "en" ? "active-lng" : ""}`}
                  onClick={() => handleLanguageChange("en")}
                >
                  EN
                </button>
                <button
                  className={`${language === "sp" ? "active-lng" : ""}`}
                  onClick={() => handleLanguageChange("sp")}
                >
                  ES
                </button>
              </div>
            </li>
            {user && (
              <>
                <Link to="/login">
                  <button
                    onClick={() => {
                      dispatch(logout());
                      dispatch(walletNull());
                      logoutUser();
                    }}
                    className="lp-btn ms-2"
                  >
                    {" "}
                    {t("buttons.logout")}
                  </button>
                </Link>
              </>
            )}
          </ul>
        </nav>
      </div> */}
      <header>
        <div className="header-wrap">
          <div className="left">
            <Link to="/">
              <img
                src={cmsImage(setting?.logo)}
                alt=""
                onError={(e) => (e.currentTarget.src = imageUrl("logo.webp"))}
              />
            </Link>
          </div>
          <div className="center">
            <ul>
              <Link
                to={"/"}
                className={
                  !location.pathname.includes("about") &&
                  !location.pathname.includes("invest") &&
                  !location.pathname.includes("contact")
                    ? "active"
                    : ""
                }
              >
                <li>{t("words.home")}</li>{" "}
              </Link>
              <Link
                to={"/about-us"}
                className={location.pathname.includes("about") ? "active" : ""}
              >
                <li>{t("words.about")}</li>{" "}
              </Link>
              <Link
                to="/franchise"
                className={location.pathname.includes("invest") ? "active" : ""}
              >
                <li> {t("words.invest")}</li>{" "}
              </Link>
              {/* <Link
                to="/contact-us"
                className={
                  location.pathname.includes("contact") ? "active" : ""
                }
              >
                <li>{t("words.contact")}</li>{" "}
              </Link> */}
            </ul>
          </div>
          <div className="right">
            <div className="button-group">
              {user && (
                <>
                  {totalUnseenNotifications > 0 && (
                    <span
                      className="not-top-icon"
                      style={language === "en" && !isMobile ? { right: "477px" } : {}}
                    >
                      {totalUnseenNotifications}
                    </span>
                  )}
                  <MdNotifications
                    onClick={toggleDropdown}
                    size={30}
                    className="m-2 white"
                  />
                  {showDropdown && user && (
                    <div className="notification-card">
                      <UserNotification
                        loader={loader}
                        notifications={notifications}
                        unseenCount={totalUnseenNotifications}
                        showDropdown={showDropdown}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="language-btn">
                <button
                  className={`${language === "en" ? "active-lng" : ""}`}
                  onClick={() => handleLanguageChange("en")}
                >
                  EN
                </button>
                <button
                  className={`${language === "sp" ? "active-lng" : ""}`}
                  onClick={() => handleLanguageChange("sp")}
                >
                  ES
                </button>
              </div>
              {user ? (
                <>
                  <button
                    onClick={() => navigate("/user/dashboard")}
                    className="lp-btn"
                  >
                    {t("titles.dashboard")}
                  </button>
                </>
              ) : admin?.adminUser ? (
                <>
                  <button
                    onClick={() => navigate("/admin/dashboard")}
                    className="lp-btn"
                  >
                    {t("titles.dashboard")}
                  </button>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    onClick={() => navigate("/login")}
                    className="simple-btn login"
                  >
                    {t("buttons.login")}
                  </button>
                  {/* <button
                    onClick={() => navigate("/registration")}
                    className="lp-btn"
                  >
                    {t("buttons.signup")}
                  </button> */}
                </>
              )}

              {user && (
                <>
                  <Link to="/login">
                    <button
                      onClick={() => {
                        dispatch(logout());
                        dispatch(walletNull());
                        logoutUser();
                      }}
                      className="lp-btn ms-2"
                    >
                      {" "}
                      {t("buttons.logout")}
                    </button>
                  </Link>
                </>
              )}
            </div>

            {/* <div className="notifivation"></div> */}
          </div>
          <div className="hamburger-mobile">
            <ul>
              <li className="hamburger" onClick={() => setIsActive(!isActive)}>
                <GiHamburgerMenu />
              </li>
            </ul>
          </div>
          <div
            className={`mobile-nav ${isActive ? "active" : ""}`}
            id="mobile-hambargur"
          >
            <div className="one">
              <div className="one-left">
                <a href="/">
                  <img
                    // src={cmsImage(setting?.logo)}
                    src={"/images/logo.webp"}
                    alt="logo"
                    style={{ width: "100px" }}
                  />
                </a>
              </div>
              <div
                className={`one-right ${isActive ? "active" : ""}`}
                onClick={() => setIsActive(!isActive)}
              >
                <RxCross2 />
              </div>
            </div>

            <div className="two" id="after-login-mobile">
              <ul>
                <Link
                  to={"/"}
                  className={location.pathname.includes("/") ? "active" : ""}
                >
                  <li>{t("words.home")}</li>{" "}
                </Link>
                <Link
                  to={"/about-us"}
                  className={
                    location.pathname.includes("about") ? "active" : ""
                  }
                >
                  <li>{t("words.about")}</li>{" "}
                </Link>
                <Link
                  to="/franchise"
                  className={
                    location.pathname.includes("invest") ? "active" : ""
                  }
                >
                  <li> {t("words.invest")}</li>{" "}
                </Link>
                <Link
                  to="/contact-us"
                  className={
                    location.pathname.includes("contact") ? "active" : ""
                  }
                >
                  <li>{t("words.contact")}</li>{" "}
                </Link>
                {user && (
                  <>
                    <Link to="/admin/dashboard">
                      <button className="lp-btn" style={{ marginTop: "10px" }}>
                        {t("titles.dashboard")}
                      </button>
                    </Link>
                    <Link to="/login">
                      <button
                        onClick={() => {
                          dispatch(logout());
                          dispatch(walletNull());
                          logoutUser();
                        }}
                        style={{ marginTop: "30px" }}
                        className="lp-btn ms-2"
                      >
                        {" "}
                        {t("buttons.logout")}
                      </button>
                    </Link>
                  </>
                )}
                {!user && (
                  <Link to="/login">
                    <button className="lp-btn" style={{ marginTop: "20px" }}>
                      {t("buttons.login")}
                    </button>
                  </Link>
                )}
                <div className="language-btnn">
                  <button
                    className={`${language === "en" ? "active-lng" : ""}`}
                    onClick={() => handleLanguageChange("en")}
                  >
                    EN
                  </button>
                  <button
                    className={`${language === "sp" ? "active-lng" : ""}`}
                    onClick={() => handleLanguageChange("sp")}
                  >
                    ES
                  </button>
                </div>
              </ul>
            </div>
          </div>
        </div>
        {location.pathname === "/en/user/referral" ||
        location.pathname === "/sp/user/referral" ? (
          ""
        ) : (
          <CallToNews />
        )}
      </header>
    </>
  );
};

export default MainHeader;
