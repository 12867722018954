import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import apis from "../../services";
import { setReserveShares } from "../../redux/slices/adminSlice";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
const ConfirmWithDrawlModal = ({
  status = true,
  toggleModal,
  amount,
  submit,
  setData,
  data,
  setModalConfirm,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Dialog maxWidth={"md"} open={status} onClose={toggleModal}>
      <DialogTitle>Confirm Withdrawl Request</DialogTitle>

      <DialogContent dividers sx={{ width: 500 }}>
        <Grid item container>
          <Grid item xs={12}>
            <Form.Group className="mb-3" controlId="reserveShares">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Full Name"
                name="fullName"
                value={data.fullName}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid>
          <Grid item xs={12}>
            <Form.Group className="mb-3" controlId="reserveShares">
              <Form.Label>Withdrawl Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={amount}
                disabled
              />
            </Form.Group>
          </Grid>
          <Grid item xs={12}>
            <Form.Group className="mb-3" controlId="reserveShares">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank Name"
                onChange={handleInputChange}
                name="bankName"
              />
            </Form.Group>
          </Grid>
          <Grid item xs={12}>
            <Form.Group className="mb-3" controlId="reserveShares">
              <Form.Label>Account Number/Card Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Account Number/Card Number"
                onChange={handleInputChange}
                name="accountNo"
              />
            </Form.Group>
          </Grid>
          <Grid item xs={12}>
            <Form.Group className="mb-3" controlId="reserveShares">
              <Form.Label>RFC Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter RFC Number"
                onChange={handleInputChange}
                name="rfcNo"
              />
            </Form.Group>
          </Grid>
        </Grid>
        <span style={{ color: "red" }}>
          Withdrawal Policy: When you submit a withdrawal request, a 30% tax
          will be deducted from the requested amount. You will receive the
          remaining 70%.
        </span>
        <br />
      </DialogContent>

      <DialogActions>
        <Grid item container justifyContent="flex-end" gap={2}>
          <Button
            className="lp-btn"
            // onClick={toggleModal}
            onClick={() => setModalConfirm(false)}
            style={{ backgroundColor: "#212529", borderColor: "#212529" }}
          >
            Cancel
          </Button>
          <Button className="lp-btn" type="submit" onClick={submit}>
            Confirm
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmWithDrawlModal;
