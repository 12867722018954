import React, { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { EntityModal, Header } from "components";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import apis from "services";

import { toast } from "react-toastify";
import useNavigate from "hooks/Navigate";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import detectLang from "utils/detectLang";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KYCModal from "components/modals/KYCModal";
import { FaRegCirclePlay } from "react-icons/fa6";

const mdTheme = createTheme();

const FranchiseDetail = () => {
  const { id } = useParams();

  const [successModal, setSuccessModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [investmentModal, setInvestmentModal] = useState(false);
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [limit, setLimit] = useState(10);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.user);
  const handleLoadMore = () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  useEffect(() => {
    const getSingleStore = async (id) => {
      if(id)
        {
          try {
            const response = await apis.getStore(id);
            console.log("response get store detail", response);
            setStore(response?.data);
            setLoading(false);
          } catch (error) {
            console.log("error", error);
            setError(error);
            setLoading(false);
          }    
        }
       
      
    };

    getSingleStore(id);
  }, []);

  console.log("get store detail", store);

  const sliderSettings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleSubmit = () => {
    // toggleModal(_id);
    if (!user) {
      return toast.info("Register yourself first to invest", { toastId: 123 });
    } else if (!store?.store?.active || store?.store?.totalShares == 0) {
      return toast.info("This store has funded fully", { toastId: 123 });
    } else if (user?.isKYCCompleted === "pending") {
      toggleInvestmentModal();
    } else if (
      user?.isKYCCompleted === "progress" ||
      user?.isKYCCompleted === "completed" ||
      user?.isKYCCompleted === "signatureVerified"
    ) {
      toggleModal();
    }
  };

  const toggleInvestmentModal = (type = "close") => {
    if (type === "open") {
      setSignatureModal(true);
      setInvestmentModal(false);
    } else if (type === "success") {
      setSuccessModal(true);
      setSignatureModal(false);
      setInvestmentModal(false);
    } else if (user?.isKYCCompleted === "pending") {
      setInvestmentModal((prevState) => !prevState);
      setSignatureModal(false);
    } else if (user?.isKYCCompleted === "progress") {
      setSignatureModal((prevState) => !prevState);
      setInvestmentModal(false);
    }
  };
  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const [isPlaying1, setIsPlaying1] = useState(false);
  const videoRef1 = useRef(null);

  const togglePlay1 = () => {
    if (videoRef1.current.paused || videoRef1.current.ended) {
      videoRef1.current.play();
      setIsPlaying1(true);
    } else {
      videoRef1.current.pause();
      setIsPlaying1(false);
    }
  };

  const truncateDescription = (text, length) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  const descriptionLength = 300;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              background: "#fff",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container
              maxWidth="lg"
              sx={{ mt: 0, mb: 4 }}
              className="storeSectionDetail"
            >
              <div className="top-heading">
                {/* <h1> {detectLang(store?.store, "title", lang)} </h1> */}
               

                <div
                  dangerouslySetInnerHTML={{
                    __html:  detectLang(store?.store, "title", lang)
                  }}
                >
  </div>

                <p>
            {/* {isExpanded
              ? detectLang(store?.store, "description", lang)
              : truncateDescription(detectLang(store?.store, "description", lang) ,descriptionLength )}
            */}
           
           
           <div
                  dangerouslySetInnerHTML={{
                    __html:  isExpanded
                    ? detectLang(store?.store, "description", lang)
                    : truncateDescription(detectLang(store?.store, "description", lang) ,descriptionLength ),
                  }}
                >
  </div>

           
            <span
              onClick={toggleDescription}
              style={{ color: "#e0201c", cursor: "pointer" }}
            >
              {isExpanded ? " Show Less" : " Show More"}
            </span>
          </p>
              </div>
              <div className="top">
                <div className="left" style={{ overflow: "hidden" }}>
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {store?.store?.files?.map((data, index) => (
                      <>
                        {data?.type === "video" ? (
                          <SwiperSlide>
                            <div className="wrap-video">
                              <video
                                ref={videoRef1}
                                src={data?.file}
                                //  controls
                              />
                              {/* <img
                                src="/assets/images/home-sec-sevenImg.png"
                                alt=""
                                className="playPause1"
                                onClick={() => togglePlay1(index)}
                              /> */}
                              <div
                                className="background"
                                style={{ opacity: isPlaying1 ? 0 : 1 }}
                              >
                                <img
                                  onClick={togglePlay1}
                                  // src={cmsImage(setting?.what?.image)}
                                  src="/assets/images/home-sec-sevenImg.png"
                                  alt=""
                                  loading="lazy"
                                />
                                <img
                                  src="/assets/images/videoPlayPause.png"
                                  alt=""
                                  className="playPause1"
                                  onClick={() => togglePlay1(index)}
                                  loading="lazy"
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide>
                            <img src={data?.file} alt="" className="type-img" loading="lazy"/>
                          </SwiperSlide>
                        )}
                      </>
                    ))}
                  </Swiper>

                  {/* <div className="slider" style={{ position: "relative" }}>
                    {<img src={store?.store?.image} alt="" />}
                    <Slider {...sliderSettings}>
                      <img src="/assets/images/sliderImg.png" alt="" />
                      <img src="/assets/images/sliderImg.png" alt="" />
                      <img src="/assets/images/sliderImg.png" alt="" />
                      <img src="/assets/images/sliderImg.png" alt="" />
                      <img src="/assets/images/sliderImg.png" alt="" />
                      <img src="/assets/images/sliderImg.png" alt="" />
                    </Slider>
                  </div> */}
                </div>
                <div className="right">
                  {/* <div className="bar"></div> */}
                  <div className="progress-barr">
                    <progress
                      id="file"
                      value={store?.store?.amountTotalInvested}
                      max={store?.store?.totalShares * 20}
                    ></progress>
                  </div>
                  {/* <div className="bar-wrap" style={{ width: "50%" }} /> */}
                  <div className="box">
                    <h1 className="green">
                      MX {store?.store?.amountTotalInvested} {t("labels.raised")}
                    </h1>
                    <p>Maximum Amount Of MX {( store?.store?.totalShares * 20)}</p>
                  </div>
                  <div className="box">
                    <h1>{store?.store?.totalInvestor}</h1>
                    <p>Investors</p>
                  </div>
                  <div className="box">
                    <img src="/assets/images/location2.png" loading="lazy" />
                    <p>{store?.store?.location?.address}</p>
                  </div>
                  <div className="box">
                    {!store?.store?.active || store?.store?.totalShares == 0 ? (
                      <button onClick={handleSubmit}>
                        {t("buttons.funding_complete")}🎉
                      </button>
                    ) : (
                      <button onClick={handleSubmit}>
                        {t("buttons.invest_now")}
                      </button>
                    )}

                    <p>$500 {t("labels.minimum_investment")}</p>
                  </div>
                </div>
              </div>
              <div className="bottom">
                {store?.store?.pitches?.length > 0 && 
                <h1 className="main-heading">Pitch</h1>
                }
                {store?.store?.pitches?.length > 0 && store?.store?.pitches
                  ?.splice(0, limit)
                  ?.map((pitch, index) => (
                    <div className="container" key={index}>
                      <div className="left">
                        {/* <h1>
                          {lang === "en"
                            ? pitch?.pitchTitleEnglish
                            : pitch?.pitchTitleSpanish}
                        </h1> */}
                        <div
                  dangerouslySetInnerHTML={{
                    __html: lang === "en"
                    ? pitch?.pitchTitleEnglish
                    : pitch?.pitchTitleSpanish,
                  }}
                >
  </div>
                      
                      
                      
                      
                      
                        {/* <p>
                          {lang === "en"
                            ? pitch?.pitchDescriptionEnglish
                            : pitch?.pitchDescriptionSpanish}
                        </p> */}


                        <div
                  dangerouslySetInnerHTML={{
                    __html:  lang === "en"
                      ? pitch?.pitchDescriptionEnglish
                      : pitch?.pitchDescriptionSpanish,
                  }}
                >
  </div>

                      </div>
                      <div className="right">
                        <img src={pitch?.pitchImage} alt="pitchImage" />
                      </div>
                    </div>
                  ))}

                {store?.store?.pitches?.length > 5 && (
                  <div className="btn-wrapper-viewMore">
                    <button onClick={handleLoadMore}>View More</button>
                  </div>
                )}
              </div>
              {store?.store && (
                <EntityModal
                  status={showModal}
                  toggleModal={toggleModal}
                  {...store?.store}
                />
              )}
              {user?.isKYCCompleted === "pending" && (
                <KYCModal
                  toggleModal={toggleInvestmentModal}
                  status={investmentModal}
                  userId={user?.id}
                  signature={false}
                />
              )}
            </Container>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default FranchiseDetail;
