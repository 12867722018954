import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";

const UserFormModal = ({ showUserModal, setShowUserModal }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    fullName: "",
    amount: "",
    bankName: "",
    accountNumber: "",
    rfcNumber: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Form Data:", formData);
    if (
      !formData.fullName ||
      !formData.amount ||
      !formData.bankName ||
      !formData.accountNumber ||
      !formData.rfcNumber
    ) {
      toast.error(t("userFormModal.toast_error"));
      return;
    }
    // try {
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    // }

    setFormData({
      fullName: "",
      amount: "",
      bankName: "",
      accountNumber: "",
      rfcNumber: "",
    });
  };
  return (
    <Modal
      show={showUserModal}
      onHide={() => setShowUserModal()}
      centered
      size="lg"
      className="userform-modal"
    >
      <div className="modal-content">
        <div className="custom-header">
          <h1>{t("userFormModal.main_heading")}</h1>
          <ImCross color="#000" onClick={() => setShowUserModal(false)} />
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="input-wrap">
              <label>{t("userFormModal.label1")}</label>
              <input
                type="text"
                name="fullName"
                placeholder={t("userFormModal.placeholder1")}
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrap">
              <label>{t("userFormModal.label2")}</label>
              <input
                type="number"
                name="amount"
                placeholder={t("userFormModal.placeholder2")}
                value={formData.amount}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrap">
              <label>{t("userFormModal.label3")}</label>
              <input
                type="text"
                name="bankName"
                placeholder={t("userFormModal.placeholder3")}
                value={formData.bankName}
                onChange={handleInputChange}
                
              />
            </div>

            <div className="input-wrap">
              <label>{t("userFormModal.label4")}</label>
              <input
                type="text"
                name="accountNumber"
                placeholder={t("userFormModal.placeholder4")}
                value={formData.accountNumber}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrap last">
              <label>{t("userFormModal.label5")}</label>
              <input
                type="text"
                name="rfcNumber"
                placeholder={t("userFormModal.placeholder5")}
                value={formData.rfcNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="btn-wrap">
              <button type="submit">{t("userFormModal.btn_submit")}</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UserFormModal;
