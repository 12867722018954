import { Fragment, useState } from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SettingsPhoneOutlinedIcon from '@mui/icons-material/SettingsPhoneOutlined';
import PhoneInput from "react-phone-input-2";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { PhotoCameraRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import utils from "utils";
import Link from 'hooks/Link';
import apis from "services";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Header, Sidebar } from "components";
import { useTranslation } from "react-i18next";
import useNavigate from "hooks/Navigate";


import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import SignUpConditionModal from 'components/modals/SignUpConditionModal';
import { useLocation } from 'react-router-dom';
import i18next from "i18next";

const NewRegistrationForm = () => {

    const searchParams = new URLSearchParams(useLocation().search);
    let query = Object.fromEntries(searchParams);

    const language = localStorage.getItem("i18nextLng");
    const { i18n } = useTranslation();
  
    useEffect(() => {
      if (localStorage.getItem("i18nextLng")?.length > 2) {
        i18next.changeLanguage("sp");
      }
    }, []);
  
    const handleLanguageChange = (value) => {
      i18n.changeLanguage(value);
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [loginMode, setLoginMode] = useState('email');
    
    const [category, setCategory] = useState('investor');
    
    const [state, setState] = useState(false);


    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const { user } = useSelector((store) => store.user);
    const { adminUser } = useSelector((store) => store.admin);

    const dispatch = useDispatch();
    useEffect(() => {
        if (user) {
            navigate("/login");
        }
        else if (adminUser) {
            navigate("/login");
        }
    }, [])

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleTogglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState(null);
    const [categories, setCategories] = useState([{title:'Investor',value:"investor"},{title:'JTC Employee',value:"jtc_employee"} , {title:'Friend',value:"friend"}]);

    const [togglePassword, setTogglePassword] = useState({ password: false, confirmPassword: false });

    const onImageHandler = (e) => setImage(e.target.files[0])

    const toggle = (key) =>
        setTogglePassword((prevState) => ({ ...prevState, [key]: !prevState[key] }));

    const submitHandler = async (e) => {
        e.preventDefault();

        const form = new FormData(e.currentTarget);
        const formData = Object.fromEntries(form);
        // concatenating first name and last name and senting it as name 

        const { firstName, lastName } = formData
        formData['name'] = firstName + " " + lastName
        formData['category']=category;
        delete formData.firstName
        delete formData.lastName

        const { password, confirmPassword } = formData;

        if (!utils.isPasswordMatch(password, confirmPassword)) {
            return toast.error('Password & Confirm Password field does not match')
        }

        try {
            setLoader(true);
            const response = await apis.registration(formData, query?.ref_code);
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                setTimeout(() => navigate("/verify", { state: { email: formData?.email } }), 2000);
            }
        } catch (e) {
            console.error(e);
            toast.error(e.message)
            setLoader(false);
        }



    }

    return (
        <>
            <div className='New-login-form-main'>
                {/* <Header /> */}
                <div className="custom-header-new">
                    <div className="left"></div>
                    <div className="right">
                        <div className="language-btnn">
                            <button
                                className={`${language === "en" ? "active-lng" : ""}`}
                                onClick={() => handleLanguageChange("en")}
                            >
                                EN
                            </button>
                            <button
                                className={`${language === "sp" ? "active-lng" : ""}`}
                                onClick={() => handleLanguageChange("sp")}
                            >
                                ES
                            </button>
                        </div>
                    </div>
                </div>
                <div className='New-login-form-inner' style={{ height: 'auto' }}>
                    <div className='New-login-form-Content'>
                        <div>
                            <div>
                                <p className='New-login-bold-text'>
                                    {t("descs.Signup_desc_1")}
                                </p>
                                <br />
                                <p className='New-login-normal-text'>
                                    {t("descs.Signup_desc_2")}
                                </p>
                            </div>
                            <div>
                                <iframe className="mt-2 login-youtube-video" width="100%" height="315" src="https://www.youtube.com/embed/13bmTsSj54I" title="YouTube video player" frameborder="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                            </div>
                        </div>
                    </div>
                    <div className='New-login-form-form'>
                        <div className='New-login-form-inside'>
                            <div className='Centre-the-content'>


                                <img
                                    onClick={() => navigate("/")}
                                    className='New-login-form-logo' src={require('../../assets/images/logo.webp')} alt="" />

                                <p className='New-login-form-heading-2'>
                                    Java Times Caffe
                                </p>
                                <p className='New-login-form-heading'>
                                    {t("descs.signup_title")}
                                </p>
                            </div>
                            <div>

                                <Form onSubmit={submitHandler}>



                                    <Form.Group className="mb-3" controlId="formBasicEmail">

                                        <Row className='d-flex justify-content-center align-items-center mt-3'>
                                            <Col lg={6} >
                                                <div className="d-flex mx-3 justify-content-center position-relative">
                                                    <Avatar sx={{ width: '4em', height: '4em' }}
                                                        src={utils.createObjectURL(image)} />
                                                    <IconButton aria-label="upload picture"
                                                        sx={{ position: "absolute", bottom: 0, left: '55%' }}
                                                        component="label">
                                                        <input hidden name="image" accept="image/*" type="file" onChange={onImageHandler} />
                                                        <PhotoCameraRounded sx={{ color: "#404040" }} />
                                                    </IconButton>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label className='Login-type-btn'>{t('labels.category')}</Form.Label>
              <InputGroup>
              <InputGroup.Text className='Input-field-icons-body'>

                                                <PersonOutlinedIcon className='Input-field-icons' />

                                            </InputGroup.Text  >

              <Form.Control
                className="Input-custom-for-login"
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <option value="">{t('labels.select_category')}</option>
                {categories.map((cat) => (
                  <option key={cat} value={cat.value}>
                    {cat.title}
                  </option>
                ))}
              </Form.Control>
              </InputGroup>
            </Form.Group>
   

                                    <Form.Group className="mb-3" controlId="firstName">
                                        <Form.Label className='Login-type-btn'>{t("labels.first_name")}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text className='Input-field-icons-body'>

                                                <PersonOutlinedIcon className='Input-field-icons' />

                                            </InputGroup.Text  >
                                            <Form.Control className="Input-custom-for-login" type="text" name='firstName' required />
                                        </InputGroup>
                                    </Form.Group>

                  


                                    <Form.Group className="mb-3" controlId="lastName">
                                        <Form.Label className='Login-type-btn'>{t("labels.last_name")}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text className='Input-field-icons-body'>

                                                <PersonOutlinedIcon className='Input-field-icons' />

                                            </InputGroup.Text  >
                                            <Form.Control className="Input-custom-for-login" name='lastName' type="text" />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='Login-type-btn'>{t("labels.nick_name")}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text className='Input-field-icons-body'>

                                                <PersonOutlinedIcon className='Input-field-icons' />

                                            </InputGroup.Text  >
                                            <Form.Control className="Input-custom-for-login" type="text" name='nickName' required />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label className='Login-type-btn'>{t("labels.email")}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text className='Input-field-icons-body'>

                                                <EmailOutlinedIcon className='Input-field-icons' />

                                            </InputGroup.Text  >
                                            <Form.Control className="Input-custom-for-login" name='email' required type="email" />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="phoneNo">
                                        <Form.Label className='Login-type-btn'>{t("labels.phone_number")}</Form.Label>
                                        <InputGroup >
                                            <PhoneInput
                                                country="mx"
                                                inputProps={{ name: 'phone' }}
                                                inputStyle={{
                                                    width: "100%",
                                                    backgroundColor: "#EDEDED",
                                                    borderRadius: "5px",
                                                    border: "none",
                                                    height: "55px",
                                                    color: "#3E3E3E",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    fontFamily: "poppins",
                                                }}
                                                required
                                            />
                                        </InputGroup>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label className='Login-type-btn'>{t("labels.password")}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text className='Input-field-icons-body'>
                                                <LockOpenOutlinedIcon className='Input-field-icons' />
                                            </InputGroup.Text>
                                            <Form.Control
                                                className="Input-custom-for-login"
                                                type={showPassword ? 'text' : 'password'}
                                                name='password'
                                            />
                                            <InputGroup.Text
                                                className='Input-field-icons-body'
                                                onClick={handleTogglePassword}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOutlinedIcon className='Input-field-icons' />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon className='Input-field-icons' />
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="confirmPassword">
                                        <Form.Label className='Login-type-btn'>{t("labels.confirm_password")}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text className='Input-field-icons-body'>
                                                <LockOpenOutlinedIcon className='Input-field-icons' />
                                            </InputGroup.Text>
                                            <Form.Control
                                                className="Input-custom-for-login"
                                                type={showPassword1 ? 'text' : 'password'}
                                                name='confirmPassword'
                                            />
                                            <InputGroup.Text
                                                className='Input-field-icons-body'
                                                onClick={handleTogglePassword1}
                                            >
                                                {showPassword1 ? (
                                                    <VisibilityOutlinedIcon className='Input-field-icons' />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon className='Input-field-icons' />
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Check
                                            type="checkbox"
                                            id="isAcceptTerms"
                                            name="isAcceptTerms"
                                            label={t("labels.isAcceptTerms")}
                                            checked={state}
                                            onChange={toggleModal}
                                            fullWidth
                                            required
                                        />
                                    </Form.Group>


                                    <div className='Login-btn-container mb-3' >

                                        <button type='submit' disabled={loader}>
                                            {loader ? t("buttons.submitting") : t("buttons.submit")}
                                        </button>
                                    </div>

                                </Form>
                                <div className="Centre-the-content text-12px mb-5">
                                    {t("descs.already_have_Account")}
                                    <button className='signup-text-login-form'> <Link to="/login">

                                        {t("buttons.login")}
                                    </Link> </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <SignUpConditionModal toggleModal={toggleModal}
                status={showModal}
                setState={setState}
            />
        </>
    )
}

export default NewRegistrationForm


