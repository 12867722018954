import React, { lazy, useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import useNavigate from "hooks/Navigate";
import {
  About,
  AdminLogin,
  BlogPage,
  Contact,
  ContactUs,
  FranchiseDetail,
  Home,
  Investment,
  InvestNow,
  InvestorForm,
  LegalEntityInvestorForm,
  Login,
  Menu,
  NotFound,
  NotificationDetails,
  Notifications,
  Offices,
  OtpVerify,
  PrivacyPolicy,
  Race,
  Registration,
  Shop,
  TeamMember,
  TermOfUse,
  WeAreJava,
} from "./screens";
import {
  CheckoutForm,
  Footer,
  Header,
  PageLoader,
  ProgressBar,
  ProtectedRoute,
  ScrollToTop,
  SideLinks,
  
} from "components";
import DashboardContent from "screens/UserDashboard/Dashboard";
import { UserInvest, Franchise } from "./screens";
import UserDetails from "./components/admin/userDetails";
import UpdateStore from "./components/admin/UpdateStore";
import Wallet from "./screens/UserDashboard/Wallet";
import CreateAdmin from "./components/admin/CreateAdmin";

import {
  AddUpdateBlog,
  AddUpdateStore,
  AddUser,
  AdminDashboard,
  Dividend,
  Stores,
  DividendHistory,
  Transactions,
  Users,
  AdminTransactions,
  DonationShareTransfer,
  DonationShareHistory,
  Blogs,
  WithDrawl,
  AdminWithDrawl,
  WithDrawlRequest,
  InvestorsPitch,
  AdminInvest,
  AddUpdateUser,
  AdminUsers,
  SubAdminWithDrawl,
  UserDocuments,
  AdminDocuments,
  AdminSendNotification,
  NotificationsHistory,
  AnnualProgress,
  SharePriceHistory,
  DonationTokenHistory,
  DonationTokenTransfer,
  TransactionsOffer,
  Offers,
  AddUpdateOffer,
} from "screens/Admin";

import { LandingPage } from "screens";

import { useDispatch, useSelector } from "react-redux";
import {
  setTotalUnseenNotifications,
  setUser,
  setUserNotifications,
} from "redux/slices/userSlice";
import Language from "./components/Language";
// import { teamMembers } from "dummyData";
import {
  adminLogout,
  setAdminNotifications,
  setTotalUnseenAdminNotifications,
} from "redux/slices/adminSlice";

import KYCRegistration from "screens/KYCRegistration";

import ForgetPassword from "screens/ForgetPassword";
import UpdatePassword from "screens/UpdatePassword";
import { Suspense } from "react";
import NewRegistrationForm from "components/forms/NewRegistrationForm";
import { Live_URL, Local_URL } from "assets/constants";
import Document from "./screens/UserDashboard/Document";

import { io } from "socket.io-client";
import PromptModal from "components/modals/PromptModal";
import KYCModal from "components/modals/KYCModal";
import SignatureModal from "components/modals/SignatureModal";
import SignatureSuccessModal from "components/modals/SignatureSuccessModal";
import Logout from "screens/Logout";
// import { onMessage } from "firebase/messaging";
// import { messaging } from "./firebase";

import Referral from "screens/Admin/Referral";
import UserReferral from "screens/UserDashboard/UserReferral";
import UserReferralPolicy from "screens/UserDashboard/UserReferralPolicy";
import AddAnnualProgress from "screens/Admin/AddAnnualProgress";
import WhatsappConnect from "components/common/WhatsappConnect";
import SignUpOfferModal from "components/modals/SignUpOfferModal";

const socket = io(Live_URL);

const App = () => {
  // LAZY LOADING
  // const LandingPage = lazy(() => import("./screens/LandingPage"));
  // const Login = lazy(() => import("./screens/Login"));
  // const PrivacyPolicy = lazy(() => import("./screens/PrivacyPolicy"));
  // const TermOfUse = lazy(() => import("./screens/TermOfUse"));
  // const NewRegistrationForm = lazy(() =>
  //   import("./components/forms/NewRegistrationForm")
  // );
  // const ForgetPassword = lazy(() => import("./screens/ForgetPassword"));
  // const AdminLogin = lazy(() => import("./screens/AdminLogin"));
  // const OtpVerify = lazy(() => import("./screens/OtpVerify"));
  // const ContactUs = lazy(() => import("./screens/ContactUs"));
  // const About = lazy(() => import("./screens/About"));
  // const UserReferralPolicy = lazy(() =>
  //   import("./screens/UserDashboard/UserReferralPolicy")
  // );
  // const BlogPage = lazy(() => import("./screens/BlogPage"));
  // const Logout = lazy(() => import("./screens/Logout"));
  // const UpdatePassword = lazy(() => import("./screens/UpdatePassword"));
  // const TeamMember = lazy(() => import("./screens/TeamMember"));
  // const NotificationDetails = lazy(() =>
  //   import("./screens/NotificationDetails")
  // );
  // const Franchise = lazy(() => import("./screens/UserDashboard/Franchise"));
  // const FranchiseDetail = lazy(() =>
  //   import("./screens/UserDashboard/FranchiseDetail")
  // );
  // const WeAreJava = lazy(() => import("./screens/WeAreJava"));
  // const Offices = lazy(() => import("./screens/Offices"));
  // const InvestNow = lazy(() => import("./screens/InvestNow"));
  // const LegalEntityInvestorForm = lazy(() =>
  //   import("./screens/LegalEntityInvestorForm")
  // );
  // const KYCRegistration = lazy(() => import("./screens/KYCRegistration"));
  // const Notifications = lazy(() => import("./screens/Notifications"));
  // const Registration = lazy(() => import("./screens/Registration"));

  // ADMIN
  // const AdminDashboard = lazy(() => import("./screens/Admin/AdminDashboard"));
  // const Users = lazy(() => import("./screens/Admin/Users"));
  // const UserDocuments = lazy(() => import("./screens/Admin/UserDocuments"));
  // const AdminDocuments = lazy(() => import("./screens/Admin/AdminDocuments"));
  // const AnnualProgress = lazy(() => import("./screens/Admin/AnnualProgress"));
  // const AddAnnualProgress = lazy(() => import("./screens/Admin/AddAnnualProgress"));
  // const UserDetails = lazy(() => import("./components/admin/userDetails"));
  // const Stores = lazy(() => import("./screens/Admin/Stores"));
  // const Dividend = lazy(() => import("./screens/Admin/Dividend"));
  // const AddUpdateStore = lazy(() => import("./screens/Admin/AddUpdateStore"));
  // const Transactions = lazy(() => import("./screens/Admin/Transactions"));
  // const AdminTransactions = lazy(() => import("./screens/Admin/AdminTransactions"));
  // const TransactionsOffer = lazy(() => import("./screens/Admin/TransactionsOffer"));
  // const Offers = lazy(() => import("./screens/Admin/Offers"));
  // const DividendHistory = lazy(() => import("./screens/Admin/DividendHistory"));
  // const Wallet = lazy(() => import("./screens/UserDashboard/Wallet"));
  // const InvestorForm = lazy(() => import("./screens/InvestorForm"));
  // const AdminWithDrawl = lazy(() => import("./screens/Admin/AdminWithDrawl"));
  // const AdminInvest = lazy(() => import("./screens/Admin/AdminInvest"));
  // const AdminSendNotification = lazy(() => import("./screens/Admin/AdminSendNotification"));
  // const NotificationsHistory = lazy(() => import("./screens/Admin/NotificationsHistory"));
  // const SharePriceHistory = lazy(() => import("./screens/Admin/SharePriceHistory"));
  // const Referral = lazy(() => import("./screens/Admin/Referral"));
  // const WithDrawlRequest = lazy(() => import("./screens/Admin/WithDrawlRequest"));
  // const DonationShareTransfer = lazy(() => import("./screens/Admin/DonationShareTransfer"));
  // const DonationTokenTransfer = lazy(() => import("./screens/Admin/DonationTokenTransfer"));
  // const DonationShareHistory = lazy(() => import("./screens/Admin/DonationShareHistory"));
  // const DonationTokenHistory = lazy(() => import("./screens/Admin/DonationTokenHistory"));
  // const Blogs = lazy(() => import("./screens/Admin/Blogs"));
  // const WithDrawl = lazy(() => import("./screens/Admin/WithDrawl"));
  // const CheckoutForm = lazy(() => import("./components/CheckoutForm"));



  const dispatch = useDispatch();
  const { user, user_analytics } = useSelector((store) => store.user);

  const { adminUser } = useSelector((store) => store.admin);
  const { pathname } = useLocation();
  socket.on("sessionExpired", (token) => {
    console.log(token, "token");
  });

  socket.on("verificationSuccess", (userData) => {
    console.log(userData, "userDatauserDatauserData");
    if (userData?.email === user?.email) {
      dispatch(setUser({ id: userData?._id, ...userData }));
    }
  });

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  // console.log(pathname, "pathnam123123e");

  const [investmentModal, setInvestmentModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);

  const toggleSuccessModal = () => {
    setSuccessModal((prevState) => !prevState);
  };

  const toggleOfferModal = () => {
    setOfferModal((prevState) => !prevState);
  };

  const toggleInvestmentModal = (type = "close") => {
    if (type === "open") {
      setSignatureModal(true);
      setInvestmentModal(false);
    } else if (type === "success") {
      setSuccessModal(true);
      setSignatureModal(false);
      setInvestmentModal(false);
    } else if (user?.isKYCCompleted === "pending") {
      setInvestmentModal((prevState) => !prevState);
      setSignatureModal(false);
    } else if (user?.isKYCCompleted === "progress") {
      setSignatureModal((prevState) => !prevState);
      setInvestmentModal(false);
    }
  };

  useEffect(() => {
    let intervalId;

    // Debugging log
    console.log("Setting up intervals with pathname:", pathname);

    if (
      (pathname != "/en/login" && pathname != "/sp/login") ||
      (pathname != "/en/registration" && pathname != "/sp/registration") ||
      pathname != "/en/franchise" ||
      pathname != "/sp/franchise"
    ) {
      intervalId = setInterval(() => {
        console.log("Toggle offerModal");

        let popup = sessionStorage.getItem("popup");
        if (!popup) {
          if (!adminUser) {
            if (
              (pathname != "/en/admin-login" && pathname != "/sp/admin-login" || pathname != "/en/login" && pathname != "/sp/login") ||
              (pathname != "/en/registration" && pathname != "/sp/registration") ||
              pathname != "/en/franchise" ||
              pathname != "/sp/franchise"
            ){
              setOfferModal(true);
              sessionStorage.setItem("popup", "true");
              setSignatureModal(false);
              setInvestmentModal(false);
              setShowModal(false);  
            }
             }
        }
      }, 10000);
    }

    if (
      !user?.isAdminApproved &&
      user &&
      user_analytics?.approved + user_analytics?.pending !== 8 &&
      user?.isInvestor &&
      (user?.isKYCCompleted === "pending" ||
        user?.isKYCCompleted === "progress")
    ) {
      intervalId = setInterval(() => {
        console.log("Toggle showModal");
        if (
          !showModal &&
          pathname !== "/en/user/document" &&
          pathname !== "/sp/user/document"
        ) {
          setShowModal(true);
        }
      }, 10000); // 10 seconds
    } else if (
      user &&
      (user?.isAdminApproved || user_analytics?.approved === 8) &&
      user?.isInvestor &&
      user?.isKYCCompleted === "pending"
    ) {
      intervalId = setInterval(() => {
        console.log("Toggle investmentModal");
        if (!investmentModal) {
          setInvestmentModal(true);
        }
      }, 10000);
    } else if (
      user &&
      (user?.isAdminApproved || user_analytics?.approved === 8) &&
      user?.isInvestor &&
      user?.isKYCCompleted === "progress"
    ) {
      intervalId = setInterval(() => {
        console.log("Toggle signatureModal");
        if (!signatureModal) {
          setSignatureModal(true);
        }
      }, 10000);
    }

    return () => {
      if (intervalId) {
        console.log("Clearing interval");
        clearInterval(intervalId);
      }
    };
  }, [
    pathname,
    user,
    showModal,
    investmentModal,
    signatureModal,
    user_analytics,
    offerModal,
  ]);

  socket.on("notification", (data) => {
    if (user) {
      if (user?.id === data?.userId) {
        dispatch(setUserNotifications(data?.notifications));
        dispatch(setTotalUnseenNotifications(data?.unSeenNotifications));
      }
    }
  });

  socket.on("notification-admin", (data) => {
    if (adminUser) {
      if (adminUser?.id === data?.adminId) {
        dispatch(setAdminNotifications(data?.notifications));
        dispatch(setTotalUnseenAdminNotifications(data?.unSeenNotifications));
      }
    }
  });

  return (
    <>
      {user?.isVerified && user?.profileCompleted > 0 && (
        <ProgressBar bgcolor="#e11f1c" completed={user?.profileCompleted} />
      )}
      <ScrollToTop />
      <WhatsappConnect />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {/* <Route path="/"> */}

          <Route path="/" element={<Language />}>
            <Route path="/:lang">
              <Route index element={<LandingPage />} />
              <Route path="login" element={<Login />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />

              <Route path="term-of-use" element={<TermOfUse />} />

              <Route path="registration" element={<NewRegistrationForm />} />
              <Route path="forget" element={<ForgetPassword />} />
              <Route path="admin-login" element={<AdminLogin />} />
              <Route path="verify" element={<OtpVerify />} />
              <Route path="contact-us" element={<ContactUs />} />
              {/* <Route path="investors-pitch" element={<InvestorsPitch />} /> */}
              <Route path="about-us" element={<About />} />

              <Route path="referral-policy" element={<UserReferralPolicy />} />

              {/* <Route
                path="investors-pitch/:id"
                element={<InvestorsPitch />}
              /> */}
              <Route path="blog/:title" element={<BlogPage />} />
              <Route path="logout" element={<Logout />} />

              <Route
                path="update-password/:email"
                element={<UpdatePassword />}
              />

              {/* <Route path="/update-password" element={<UpdatePassword/>} /> */}

              <Route
                path="team/:id"
                element={<TeamMember />}
              />
              {/* User Route */}
              <Route element={<ProtectedRoute auth />}>
                <Route
                  path="notification/:id"
                  element={<NotificationDetails />}
                />
                {/* <Route path="invest" element={<UserInvest />} /> */}
                <Route path="franchise" element={<Franchise />} />
                ``
                <Route path="franchise/:id" element={<FranchiseDetail />} />
                ``
                <Route path="we-are-java" element={<WeAreJava />} />
                <Route path="offices" element={<Offices />} />
                {/* <Route path="contact" element={<Contact />} /> */}
                <Route path="invest-now" element={<InvestNow />} />
                <Route
                  path="legal-entity-investor"
                  element={<LegalEntityInvestorForm />}
                />
                <Route path="kyc-registration" element={<KYCRegistration />} />
                {/* <Route path="new-registration" element={<NewRegistrationForm />} /> */}
                <Route path="notifications" element={<Notifications />} />
                <Route
                  path="investment-registration"
                  element={<Registration />}
                />
                {/* <Route path="admin/master/YWRtaW4=" element={<CreateAdmin />} /> */}
              </Route>
              {/*NOTE:: Admin Private Routes here*/}
              <Route path="admin/" element={<ProtectedRoute isAdmin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="users" element={<Users />} />
                <Route path="users-document" element={<UserDocuments />} />

                <Route path="users-document/:id" element={<AdminDocuments />} />

                <Route path="annual-progress" element={<AnnualProgress />} />
                <Route
                  path="annual-progress/add"
                  element={<AddAnnualProgress />}
                />

                <Route path="user-details/:id" element={<UserDetails />} />
                <Route path="stores" element={<Stores />} />
                <Route path="dividend" element={<Dividend />} />
                <Route path="stores/add/" element={<AddUpdateStore />} />
                <Route path="stores/add/:id" element={<AddUpdateStore />} />
                <Route path="store/update/:id" element={<UpdateStore />} />
                <Route path="users/add" element={<AddUser />} />
                <Route path="users/add/:id" element={<AddUpdateUser />} />

                <Route path="transactions" element={<Transactions />} />
                <Route
                  path="admin-transactions"
                  element={<AdminTransactions />}
                />
                <Route
                  path="transactions-offers"
                  element={<TransactionsOffer />}
                />
                <Route path="offers" element={<Offers />} />

                <Route path="offers/add/" element={<AddUpdateOffer />} />
                <Route path="offers/add/:id" element={<AddUpdateOffer />} />

                <Route path="dividend-history" element={<DividendHistory />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="investor" element={<InvestorForm />} />
                <Route path="withdrawl" element={<AdminWithDrawl />} />
                <Route path="admin-invest/:id" element={<AdminInvest />} />
                <Route
                  path="send-notification"
                  element={<AdminSendNotification />}
                />

                <Route
                  path="notification-history"
                  element={<NotificationsHistory />}
                />
                <Route
                  path="share-price-history"
                  element={<SharePriceHistory />}
                />

                <Route path="referral-history" element={<Referral />} />

                <Route
                  path="withdrawl-request"
                  element={<WithDrawlRequest />}
                />
                <Route
                  path="share-transfer"
                  element={<DonationShareTransfer />}
                />
                <Route
                  path="token-transfer"
                  element={<DonationTokenTransfer />}
                />
                <Route
                  path="share-transfer-history"
                  element={<DonationShareHistory />}
                />
                <Route
                  path="token-transfer-history"
                  element={<DonationTokenHistory />}
                />

                <Route path="blog" element={<Blogs />} />
                <Route path="blog/add/:id" element={<AddUpdateBlog />} />
                <Route path="blog/add/" element={<AddUpdateBlog />} />
              </Route>

              {/*NOTE:: Sub Admin Private Routes here*/}
              <Route path="sub-admin/" element={<ProtectedRoute isSubAdmin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="blog" element={<Blogs />} />
                <Route path="blog/add/:id" element={<AddUpdateBlog />} />
                <Route path="blog/add/" element={<AddUpdateBlog />} />
                <Route path="stores" element={<Stores />} />
                <Route path="stores/add/" element={<AddUpdateStore />} />
                <Route path="stores/add/:id" element={<AddUpdateStore />} />
                <Route path="store/update/:id" element={<UpdateStore />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="users" element={<AdminUsers />} />
                <Route path="offers" element={<Offers />} />
                <Route path="offers/add/" element={<AddUpdateOffer />} />
                <Route path="offers/add/:id" element={<AddUpdateOffer />} />
                <Route path="withdrawl" element={<SubAdminWithDrawl />} />
              </Route>

              {/*NOTE:: User Private Routes here*/}
              <Route exact path="user/" element={<ProtectedRoute />}>
                <Route path="dashboard" element={<DashboardContent />} />
                <Route path="withdrawl" element={<WithDrawl />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="document" element={<Document />} />
                <Route path="referral" element={<UserReferral />} />

                <Route path="checkout" element={<CheckoutForm />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <PromptModal toggleModal={toggleModal} status={showModal} />

      {user?.isKYCCompleted === "pending" && (
        <KYCModal
          toggleModal={toggleInvestmentModal}
          status={investmentModal}
          userId={user?.id}
          signature={true}
        />
      )}

      {user?.isKYCCompleted === "progress" && (
        <SignatureModal
          toggleModalSignature={toggleInvestmentModal}
          status={signatureModal}
          userId={user?.id}
        />
      )}

      <SignatureSuccessModal
        toggleModalSuccess={toggleSuccessModal}
        status={successModal}
        userId={user?.id}
      />

      <SignUpOfferModal status={offerModal} toggleModal={toggleOfferModal} />
    </>
  );
};

export default App;
