import { Footer, MainHeader, TeamCard } from "components";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import imageUrl from "utils/imageUrl";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import useNavigate from "hooks/Navigate";
import { useParams } from "react-router-dom";
import cms from "../services/cms";
import CustomHelmet from "../components/CustomHelmet";
import apis from "services";
import { toast } from "react-toastify";

function ContactUs() {
  const [data, setData] = useState({ name: '', email: '', phone: '', message: '' });
  const [showDropdown, setShowDropdown] = useState(false);

  const { lang: _lang } = useParams();
  const lang = _lang || "en";
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [seos, setSeos] = useState({});
  const { language, setLanguage, t } = useTranslation();
  const fetchSeo = async () => {
    const res = await cms.getSeo('contact-us');
    setSeos(res?.data?.data);
  };
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChange = (value) => {
    setData((prevState) => ({ ...prevState, phone: value }));
  }


  const submit = async (e) => {
    e.preventDefault();
    const res = await apis.contactUs(data);
    if (res?.status) {
      toast.success(res?.data?.message)
      navigate("/")
    }
  };
  useEffect(() => {
    (async () => {
      await fetchSeo();
    })();
  }, []);
  
  return (
    <>
      <div onClick={() => { if (showDropdown) setShowDropdown(!showDropdown) }}>
        <div className="page-banner" >
          <MainHeader showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
          {
            seos && seos?.seos ? <CustomHelmet seo={seos?.seos} lang={lang} /> : ''
          }
          <img
            src={imageUrl("about_img.webp")}
            className="background-img"
            alt=""
          />
          <div className="page-banner-body">
            <p className="page-banner-title">{t("titles.contact_us")}</p>
          </div>
        </div>
        <section className="pt-5">
          <Container>
            <Row>
              <Col lg={6} className="pb-5">
                <p className="section-title text-start fw-bold mb-0">
                  {t("titles.contact_us")}
                </p>
                <p className="para">{t("descs.contact_us")}</p>
                <form onSubmit={(e) => submit(e)}>
                  <p className="para pt-4">{t("descs.contact_page")}</p>
                  <div className="pt-2 radio-group">
                    {/* <div className="d-flex align-items-center">
                    <input
                      className="me-2"
                      type="radio"
                      id="radio1"
                      name="option"
                      required
                    />
                  
                  <label htmlFor="radio2">{t("labels.investment")}</label>
                  </div>
                  */}
                    {/* <div className="d-flex align-items-center">
                    <input
                      className="me-2"
                      type="radio"
                      id="radio2"
                      name="option"
                      required
                    />
                    <label htmlFor="radio2">{t("labels.investment")}</label>
                  </div> */}
                  </div>
                  <label htmlFor="name" className="para">
                    {t("labels.name")}
                  </label>
                  <input
                    required
                    name="name"
                    type="text"
                    value={data?.name}
                    onChange={onChangeHandler}
                    placeholder="Name"
                    className="custom-control"
                  />
                  <label htmlFor="email" className="para pt-4">
                    {t("labels.email")}
                  </label>
                  <input
                    required
                    name="email"
                    type="email"
                    value={data?.email}
                    onChange={onChangeHandler}

                    placeholder="you@example.com"
                    className="custom-control"
                  />
                  <label htmlFor="phone" className="para pt-4">
                    {t("labels.phone")}
                  </label>
                  <PhoneInput
                    required
                    name="phone"
                    buttonClass="phone-input-button"
                    inputClass="phone-input"
                    country={"es"}
                    value={data?.phone}
                    onChange={handleChange}
                  />
                  <label htmlFor="message" className="para pt-4">
                    {t("labels.message")}
                  </label>
                  <textarea
                    required
                    name="message"
                    rows="8"
                    id="message"
                    value={data?.message}
                    onChange={onChangeHandler}

                    className="custom-control"
                  />
                  <p className="para lh-0 pt-3">
                    {t("descs.contact_page_2")}{" "}
                    <a className="red">{t("labels.privacy_policy")}</a>
                  </p>
                  <div className="center-x mt-4">
                    <button type="submit" className="lp-btn square-btn w-50 py-2">
                      {t("buttons.send_message")}
                    </button>
                  </div>
                </form>
              </Col>
              <Col lg={6} className="pb-5">
                <div className="map-iframe">
                  <iframe
                    src="https://embed.waze.com/iframe?zoom=17&lat=25.562276&lon=-103.494560&ct=livemap"
                    width="600"
                    height="450"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </div>
    </>

  );
}

export default ContactUs;
