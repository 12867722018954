import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "../../services";
import useNavigate from "hooks/Navigate";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "components/@material-extend/CustomToolbar";
import { toast } from "react-toastify";
import UsersTable from "../../components/datagrid/UsersTable";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Link from "hooks/Link";
import DeleteModal from "components/modals/DeleteModal";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
export const Users = () => {
  const [users, setUsers] = useState([]);

  const [status, setStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  const [showDiv, setShowDiv] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  
  const [tab, setTab] = useState("all");
  const [show, setShow] = useState(false);

console.log(tab,"tabtab")

  const [userId, setUserId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  /*Methods*/
  const fetchAllUsers = async () => {
    const res = await apis.getAllUser();
    if (res.data.status) {
      setUsers(res.data.users)
      setFilteredUsers(res.data.users)
    };
  };

  const deleteHandler = async (id) => {
    setLoader(true);
    const res = await apis.deleteUser(id);
    if (res.data.status) {
      toast.success(res.data.message);
      await fetchAllUsers();
    }
    else toast.error(res.data?.message);

    handleClose();
    setLoader(false);
  };

  const resetEmailHandler = async (body) => {
    const res = await apis.resetEmail(body);
    if (res.data.status) {
      toast.success(res.data.message);
    } else toast.error(res.data.message);
  };


  const approveHandler = async (id) => {
    const res = await apis.approveUser(id);
    if (res.data.status) {
      toast.success(res.data.message);
      await fetchAllUsers();
    } else toast.error(res.data.message);
  };

  useEffect(() => {
    (async () => {
      await fetchAllUsers();
    })();
  }, []);

  // const NewToolbar = () => (
  //     <Toolbar
  //         buttons={[
  //             <Button
  //                 variant="outlined"
  //                 py={2}
  //                 size="small"
  //                 onClick={() => navigate("add")}
  //                 color="error"
  //             >
  //                 {t("buttons.add_user")}
  //             </Button>,
  //         ]}
  //     />
  // );

  const handleClose = () => {
    setShow(false);
    setUserId("");
  };

  const openModal = (id) => {
    setUserId(id);
    setShow(true);
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    setShowDiv(true);

    setTimeout(() => {
      setShowDiv(false);
    }, 1000);
  };

  // let tables;
  // if (searchQuery) {
  //   console.log("Call search")
 
  //   tables = UsersTable(filteredUsers, {
  //    navigate,
  //     approveHandler,
  //     deleteHandler,
  //     openModal,
  //     resetEmailHandler,
  //     copyToClipboard
  //   });
  // }
  // else {
    
  //   console.log("Call table")
  //   tables = UsersTable(users, {
  //     navigate,
  //     approveHandler,
  //     deleteHandler,
  //     openModal,
  //     resetEmailHandler,
  //     copyToClipboard
  //   });
  // }





  let filteredData ,tables ;

   filteredData = tab === 'all' ? filteredUsers : filteredUsers.filter(item => item.category == tab);

   tables = UsersTable(filteredData, {
    navigate,
     approveHandler,
     deleteHandler,
     openModal,
     resetEmailHandler,
     copyToClipboard
   });

  const filterUsers = () => {
    const filtered = filteredData?.filter((user) => {
      const fullName = `${user.name} ${user.email}`.toLowerCase();
      const walletId = user.wallet;
      return fullName?.includes(searchQuery.toLowerCase()) || walletId?.includes(searchQuery);
    });
    setFilteredUsers(filtered);
  };


  useEffect(()=>{
    if(searchQuery == "")
    {
      
      setFilteredUsers(users);
    }
      },[searchQuery,tab])
  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container sx={{ mt: 0, mb: 4 }}>
              <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.users")}</h3>
                <Link to={"/admin/users/add"} className="lp-btn">
                  {t("buttons.add_user")}
                </Link>
              </div>
              <Col xs={12} md={6} lg={4} className="mt-3 p-0">
                <Form.Group className="my-2">
                  <Form.Label>Search by Name or Email or WalletId</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name or email or walletId"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={filterUsers}
                  />
                </Form.Group>
              </Col>
              <div className="share-type d-flex justify-content-center mt-2">
              <Button
                className={tab === "all" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("all")}
              >
                All
              </Button>
              <Button
                className={tab === "friend" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("friend")}
              >
                Friends
              </Button>
              <Button
                className={tab === "investor" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("investor")}
              >
                Investor
              </Button>
              <Button
                className={tab === "jtc_employee" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("jtc_employee")}
              >
                JTC Employee
              </Button>
         
            </div>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                {showDiv && (
                  <div className={`transition-div ${showDiv ? "show" : ""}`}>
                    <p>copy wallet address successfully</p>
                  </div>
                )}
                <DataGrid
                  //checkboxSelection
                  rows={tables?.rows}
                  columns={tables?.columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
         
              <DeleteModal
                show={show}
                handleClose={handleClose}
                handleSubmit={deleteHandler}
                loader={loader}
                id={userId}
                title={"Delete User"}
              />
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default Users;
