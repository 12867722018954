import React from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  Delete,
  RemoveModerator,
  Send,
  VerifiedUser,
  Visibility,
  MonetizationOn
} from "@mui/icons-material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
/**
 * @param users
 * @param methods
 * @return {{columns: [{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},null], rows: *[]}}
 */
const UsersTable = (users = [], methods = {}) => {
  const { navigate, approveHandler, deleteHandler, openModal ,resetEmailHandler,copyToClipboard} = methods;
  const { language, setLanguage, t } = useTranslation();

  const columns = [
    { field: "id",
     headerName: "#",    
     width: 50 },


     {
      field: "category",
      headerName: t("fields.category"),
      minWidth: 150,
      flex: 1,
    },
     
    {
      field: "fullName",
      headerName: t("fields.fullName"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "WalletId",
      headerName: t("fields.WalletID"),
      minWidth: 290,
      flex: 1,
      renderCell: (props) => {
        const isVerified = props.row.WalletId;
        return isVerified && <span onClick={() => copyToClipboard(props?.row?.WalletId)}>{props?.row?.WalletId} {" "} <ContentCopyIcon/> </span>
      }
    },

    { field: "email", headerName: t("fields.email"), minWidth: 150, flex: 1 },
    {
      field: "verified",
      headerName: t("fields.verified"),
      width: 100,
        renderCell: (props) => {
        const isVerified = props.row.verified;
        return isVerified ?<span style={{color:'green'}}>Verified </span>: <span style={{color:"red"}}>Not Verified</span>
      }
    },
    {
      field: "investor",
      headerName: t("fields.investor"),
      width: 100,
        renderCell: (props) => {
        const isInvestor = props.row.investor;
        return isInvestor ? <span style={{color:'green'}}>Yes</span>: <span style={{color:"red"}}>Not yet</span>
      }
    },

    
    {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("fields.actions"),
      minWidth: 250,
      flex: 1,
      renderCell: (props) => {
        const isVerified = props.row.verified;
        const id = props.row._id;
        return (
          <Grid container>
            
            <Grid item>
              <Tooltip title="View" arrow>
                <IconButton
                  size="small"
                  onClick={() => navigate(`/admin/user-details/${id}`)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Grid>
           
            <Grid item>
              <Tooltip title="Edit" arrow>
                <IconButton
                  size="small"
                  onClick={() => navigate(`/admin/users/add/${id}`)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
           

            <Grid item>
              {isVerified ? (
                <Tooltip title="Disable Users" arrow>
                  <IconButton size="small" onClick={() => approveHandler(id)}>
                    <RemoveModerator className="failure" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Verify Users" arrow>
                  <IconButton size="small" onClick={() => approveHandler(id)}>
                    <VerifiedUser className="success" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <Tooltip title="Delete" arrow>
                <IconButton size="small" onClick={() => openModal(id)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Invest Manually" arrow>
                <IconButton
                  size="small"
                  
                  onClick={() => navigate(`/admin/admin-invest/${id}`)}
                >
                  <MonetizationOn className="success" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Send Password Reset Email" arrow>
                <IconButton size="small">
                  <Send  onClick={() => resetEmailHandler(props.row)}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const rows =
    users.length === 0
      ? []
      : [
          ...users.map((user, id) => ({
            id: id + 1,
            fullName: user.name,
            WalletId: user.wallet,
            email: user.email,
            verified: user.isVerified,
            investor: user.isInvestor,
            createdAt: user.createdAt,
            category: user.category,
            _id: user._id,
          })),
        ];

  return { rows, columns };
};

export default UsersTable;
