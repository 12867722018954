import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTransition } from "react";
import shapeImg from "../../../src/assets/images/shape.png";
import lowerShapeImg from "../../../src/assets/images/lower-shape.png";
import useLocalizedNavigate from "hooks/Navigate";
const SignUpOfferModal = ({ status, toggleModal }) => {
  const { language } = useSelector((store) => store.language);
  // const { t } = useTranslation();

  const { user } = useSelector((store) => store.user);
  let navigate = useLocalizedNavigate();

let lang = localStorage.getItem("i18nextLng");

  return (
    <Modal show={status} onHide={toggleModal} size="md" centered className="offer-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{padding:"0px"}}>
        <div className="offer-modal-sec">
          <h6>Invest Now And Get</h6>
          <div className="offer-modal-img">
            <img className="shape-img" src={shapeImg} />
            <div className="text">
              <p>
                An Extra{" "}
                <span style={{ marginTop: "0px" }}>
                  10% On Top Of{" "}
                </span>{" "}
                <span clasName="second">Your Investment!</span>{" "}
              </p>
            </div>
          </div>

          <p style={{ textAlign: "center", paddingTop: "30px", color: "#000" , fontSize:"12px" }}>
            {lang == "en" ? "New Benefit for Java 300 Investors Take advantage of this limited-time offer! If you purchase up to 250 shares, you will receive an additional 10% on your 70% investment. This promotion is available both on the website and the mobile app." : "Nuevo Beneficio para Inversionistas Java 300 ¡Aprovecha esta oferta por tiempo limitado! Si compras hasta 250 acciones, recibirás un 10% adicional sobre tu inversión del 70%. Esta promoción está disponible tanto en la versión web como en la app móvil. " }
          </p>

          <button
            className="lp-btn-invest mt-4"
            onClick={() => {
              if (user) {
                navigate("/franchise");
                toggleModal();
              } else {
                navigate("/registration");
                toggleModal();
              }
            }}
          >
            {" "}
            {user ? lang == "sp" ? "Invertir Ahora" : "Invest Now" : lang == "sp" ? "Regístrate" :  "Signup"}
          </button>
          {/* <div className="timer-sec">
          <div
            className="box"
          >
            <hr className="hr" />
            <div
              className="content"
            >
              07
            </div>
          </div>

          <div
            className="box"
          >
            <hr className="hr" />
            <div
              className="content"
            >
              11
            </div>
          </div>

          <div
            className="box"
          >
            <hr className="hr" />
            <div
              className="content"
            >
              01
            </div>
          </div>

          <div
            className="box"
          >
            <hr className="hr" />
            <div
              className="content"
            >
              02
            </div>
          </div>
          </div> */}

          <img
            style={{ width: "100%", objectFit: "contain"}}
            src={lowerShapeImg}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpOfferModal;
