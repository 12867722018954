import React, {useEffect, useState} from "react";
import {Box, Container, Grid} from "@mui/material";
import apis from "services";
import {DataGrid} from "@mui/x-data-grid";
import StoresTable from "components/datagrid/StoresTable";
import {toast} from "react-toastify";
import Toolbar from "../../components/@material-extend/CustomToolbar";
import ImagePreview from "../../components/modals/ImagePreview";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import {Header} from "components";
import { useTranslation } from "react-i18next";
import Link from 'hooks/Link';
import useNavigate from "hooks/Navigate";
import DeleteModal from "components/modals/DeleteModal";
import { useSelector } from "react-redux";
import OffersTable from "components/datagrid/OffersTable";

const Offers = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const {adminUser} = useSelector((store) => store.admin);
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [offerId, setOfferId] = useState(null);

    const [offers, setOffers] = useState([]);
 
    const fetchAllOffers = async () => {
        const res = await apis.getAllOffer();
        setOffers(res?.data?.offers);
    };

    const handleDisableStore = async (id) => {
        const res = await apis.disableOffer(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllOffers();
        }
    };
    const handleEnableStore = async (id) => {
        const res = await apis.enableOffer(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllOffers();
        }
    };
    const deleteHandler = async (id) => {
        setLoader(true);
        const res = await apis.deleteOffer(id);
        if (res?.data?.status) {
            toast.success(res.data?.message);
            await fetchAllOffers();
            
        } else toast.error(res.data?.message);
        handleClose();
        setLoader(false)
    };


    
    useEffect(() => {
        (async () => {
            await fetchAllOffers();
        })();
    }, []);
 
    const openModal = (id) => {
        setOfferId(id);
        setShow(true);
      };
    const methods = {
        handleEnableStore,
        handleDisableStore,
        navigate,
        openModal,
    };
    const {rows, columns} = OffersTable(offers, methods);

    const handleClose = () => {
        setShow(false);
        setOfferId("");
      };
   

  
 


    return (
        <>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header/>
                        <Container maxWidth="lg" sx={{mt: 0, mb: 4}}>
                            <div className="flex-with-title">
                                <h3 className="dashboard-title">
                                    {t("titles.franchise")}
                                </h3>
                                <Link to={adminUser?.type === "owner" ?  "/admin/offers/add" : "/sub-admin/offers/add"} className="lp-btn">
                                    {t("buttons.add_offer")}
                                </Link>
                            </div>
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                className="mt-4"
                                sx={{height: "600px"}}
                            >
                                <DataGrid
                                    // checkboxSelection
                                    rows={rows}
                                    columns={columns}
                                    // onSelectionModelChange={(newSelectionModel) =>
                                    //     setSelectionModel(newSelectionModel)
                                    // }
                                    // selectionModel={selectionModel}
                                    // components={{Toolbar: NewToolbar}}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                />
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </Box>
            <DeleteModal 
             show={show}
             handleClose={handleClose}
             handleSubmit={deleteHandler}
             loader={loader}
             id={offerId}
             title={"Delete Offer"}
      />

        </>
    );
};

export default Offers;
