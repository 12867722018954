/**
 @param stores
 @param methods
 */
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  DeleteOutline,
  RemoveModeratorOutlined,
  VerifiedUserOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OffersTable = (offers = [], methods = {}) => {
  const { handleEnableStore, handleDisableStore, openModal } = methods;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "#", width: 50, hide: false },
    { field: "type", headerName: t("fields.type"), width: 220 },
    { field: "targetShares", headerName: t("fields.targetShares"), width: 150 },
    { field: "rewardPercentage", headerName: t("fields.rewardPercentage"), width: 150 },
    { field: "active", headerName: t("fields.offersActive"), width: 100 },
    {
      field: "actions",
      headerName: t("fields.actions"),
      width: 150,
      renderCell: ({ row }) => {
        const isActive = row.active == "Yes";
        const id = row._id;

        return (
          <Grid container>
            <Grid>
              <Tooltip title="View & Update" arrow>
                <IconButton size="small" onClick={() => navigate(`add/${id}`)}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              {isActive ? (
                <Tooltip title="Store Offer Disabled" arrow>
                  {/* <IconButton size="small" onClick={() => toast.info("Store funded already active")}> */}
                  <IconButton
                    size="small"
                    onClick={() => handleDisableStore(id)}
                  >
                    <VerifiedUserOutlined className="success" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Store Offer Active" arrow>
                  <IconButton
                    size="small"
                    onClick={() => handleEnableStore(id)}
                  >
                    <RemoveModeratorOutlined className="failure" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid>
              <Tooltip title="Delete Store" arrow>
                <IconButton size="small" onClick={() => openModal(id)}>
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const rows =
  offers.length === 0
      ? []
      : [
          ...offers.map((offer, index) => ({
            id: index + 1,
            type: offer.type,
            targetShares: offer.targetShares,
            rewardPercentage: offer.rewardPercentage,
            active: offer.isActive ? "Yes" : "No",
            _id: offer._id,
          })),
        ];

  return { columns, rows };
};

export default OffersTable;
