import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import apis from "services";
import { DataGrid } from "@mui/x-data-grid";
import AdminWithDrawlTable from "components/datagrid/AdminWithDrawlTable";
import { toast } from "react-toastify";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import ApproveWithDrawlModal from "components/modals/ApproveWithDrawlModal";
import WithdrawlReceiptModal from "components/modals/WithdrawlReceiptModal";

import useNavigate from "hooks/Navigate";
const AdminWithDrawl = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [receiptmodal, setReceiptModal] = useState(false);

  const [item, setItem] = useState({});

  const [withDrawId, setWithDrawlId] = useState("");

  const [amount, setAmount] = useState(false);
  const [fullName, setFullName] = useState(false);
  const paidAmount = Number(amount - (amount * 0.30));
  

  const handleShow = () => {
    setStatus(true);
  };
  const handleClose = () => {
    setStatus((prev) => !prev);
  };
  const [withDrawl, setWithDrawl] = useState([]);

  const fetchAllWithDrawlUser = async () => {
    const res = await apis.getAllWithdrawl();
    setWithDrawl(res?.data?.withDrawl);
  };

  const handleApprovedWithDrawl = async (id, amount, fullName) => {
    setWithDrawlId(id);
    setAmount(amount);
    setFullName(fullName);
    handleShow();
  };

  useEffect(() => {
    (async () => {
      await fetchAllWithDrawlUser();
    })();
  }, []);

  const toggleReceiptModal = (dataItem) => {
    setItem(dataItem);
    setReceiptModal((prevState) => !prevState);
  };
  const methods = {
    navigate,
    handleApprovedWithDrawl,
    toggleReceiptModal,
  };

  const { rows, columns } = AdminWithDrawlTable(withDrawl, methods);

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              <div className="flex-with-title">
                <h3 className="dashboard-title">
                  {t("titles.withDrawl-user")}{" "}
                </h3>
              </div>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>

      <ApproveWithDrawlModal
        status={status}
        close={handleClose}
        amount={amount}
        fullName={fullName}
        paidAmount={paidAmount}
        withDrawId={withDrawId}
        fetchAllWithDrawlUser={fetchAllWithDrawlUser}
      />
      <WithdrawlReceiptModal
        toggleModal={toggleReceiptModal}
        status={receiptmodal}
        data={item}
      />
    </>
  );
};

export default AdminWithDrawl;
