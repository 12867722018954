import { Footer, MainHeader } from "components";
import { useTranslation } from "react-i18next";
import React, { useEffect,  useState } from "react";
import imageUrl from "utils/imageUrl";
import { useSelector, useDispatch } from "react-redux";
import {
  setTotalReferrals,
  setUser,
  setUserReferrals,
} from "redux/slices/userSlice";
import apis from "services";
import Link from "hooks/Link";
import { formatDate } from "utils/formatDate";

import { CircularProgress, Pagination } from "@mui/material";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useLocationNavigation from "hooks/useLocationNavigation";

function UserReferral() {
  const { user, notifications, referral, referralTotal } = useSelector(
    (store) => store.user
  );

  // const navigate = useLocationNavigation();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  const getReferral = async () => {
    try {
      setLoading(true);
      const res = await apis.getUserReferral(user?.id);
      if (res?.data?.status) {
        dispatch(setUserReferrals(res?.data?.referral));
        dispatch(setTotalReferrals(res?.data?.totalReferral));
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (user) {
        await getReferral();
      }
    })();
  }, []);

  const generateLink = async () => {
    // if (user && !user?.isInvestor && !user?.referralId) {
    //   toast.error("Please invest in any store to generate a referral link");
    // }

    // else

    if (user) {
      try {
        setLoading(true);
        const response = await apis.generateLink(user?.id);
        if (response?.status === 200) {
          const user = response?.data?.user;
          dispatch(setUser({ id: user?._id, ...user }));
          toast.success(response?.data?.message);
          await getReferral();
        }

        setLoading(false);
      } catch (e) {
        console.error(e);
        toast.error(e.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div
        onClick={() => {
          if (showDropdown) setShowDropdown(!showDropdown);
        }}
      >
        <div className="page-banner">
          <MainHeader
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
          />

          <img
            src={imageUrl("about_img.webp")}
            className="background-img"
            alt=""  
          />
          <div className="page-banner-body">
            <p className="page-banner-title mx-3">{t("referal.Referrals_invite")}</p>
          </div>
        </div>
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-lg-10">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center m-5">
                    <CircularProgress color="error" />
                  </div>
                ) : (
                  <>
                    <div className="container d-flex justify-content-between">
                      <h2 className="section-top-title mb-4">
                      {t("referal.Referrals")}
                      </h2>

                      <div className="d-flex justify-content-center referel-inputfield">
                        <Link to="/referral-policy">
                          <span className="lp-btn">{t("referal.Referrals_Policy")}</span>
                        </Link>
                      </div>
                    </div>

                    <div className="container mb-5">
                      <div className="invite-friends">
                        <h4>{t("referal.Invite_your_friends")}</h4>
                        {/* <div className="input-group">
               <input
                ref={selectReference}
                onClick={() => {
                  navigator.clipboard.writeText(referral);
                  toast.success(t("Copied to clipboard"));
                  selectReference.current.select();
                }}
                placeholder="https://investin.javatimescaffe.com"
                type="url"
                className="form-control referel-inputfiel"
                id="url"
                defaultValue={referral}
                readOnly
              />
              <button
                type="button"
                className="btn copy-url-btn"
                onClick={() => {
                  navigator.clipboard.writeText(referral);
                  toast.success(t("Copied to clipboard"));
                  selectReference.current.select();
                }}
              >
                <i className="fa fa-clone" />
              </button>
            </div> */}

                        {user?.referralId ? (
                          <div
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `https://investin.javatimescaffe.com/sp/registration?ref_code=${user?.referralId}`
                              );
                              toast.success(t("Copied to clipboard"));
                            }}
                            className="d-flex justify-content-center checkout-field-referral-box"
                          >
                            <span className="referel-inputfield">{`https://investin.javatimescaffe.com/sp/registration?ref_code=${user?.referralId}`}</span>
                            <p className="copy-clabe">
                            {t("referal.copy")}&nbsp;
                              <ContentCopyIcon />
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center referel-inputfield">
                              <button
                                className="lp-btn"
                                onClick={() => generateLink()}
                              >
                                {t("referal.Genrate_Refferal_Link")}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="container">
                      <div className="section-wrapper rounded-sm boxShadow">
                        <div className="rewards-inviter mb-25">
                          <div className="single-item">
                            <h4>{t("referal.Total_Rewards")}</h4>
                            <h2>
                              {referralTotal || 0} <span />
                            </h2>
                          </div>
                          <div className="single-item">
                            <h4>{t("referal.Total_Invited")}</h4>
                            <h2>{referral?.length || 0}</h2>
                          </div>
                        </div>
                        {/* <div className="referrals-table">
              <h4 className="section-title-medium">{t("My Referrals")}</h4>
              <div className="table-responsive">
                <table
                  className="table cp-user-custom-table table-borderless text-center dataTable no-footer"
                  id="DataTables_Table_0"
                >
                  <thead>
                    <tr>
                      <th
                        className="referral-level"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Level 1"
                      >
                        {t("Level 1")}
                      </th>
                      <th
                        className="referral-level"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Level 2"
                      >
                        {t("Level 2")}
                      </th>
                      <th
                        className="referral-level"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Level 3"
                      >
                        {t("Level 3")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id="" role="row referral" className="">
                      <td className="referral-text">
                        {allData?.referralLevel[1]}
                      </td>
                      <td className="referral-text">
                        {allData?.referralLevel[2]}
                      </td>
                      <td className="referral-text">
                        {allData?.referralLevel[3]}
                      </td>
                    </tr>
                    {allData?.referralLevel.length == 0 && (
                      <td colSpan={5} className="text-center referral-text">
                        <b>{t("No Data available")}</b>
                      </td>
                    )}

                    <tr>
                      <td colSpan={3} />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
                        {/* <div className="referrals-table">
              <h4 className="section-title-medium">{t("My References")}</h4>
              <div className="table-responsive">
                <table className="table dataTable cp-user-custom-table table-borderless text-center">
                  <thead>
                    <tr>
                      <th className="">{t("Full Name")}</th>
                      <th className="">{t("Email")}</th>
                      <th className="">{t("Level")}</th>
                      <th className="">{t("Joining Date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData?.referrals?.map((data , index) => (
                      <tr key={index}>
                        <td className="referral-text">{data?.full_name}</td>
                        <td className="referral-text">{data?.email}</td>
                        <td className="referral-text">{data?.level}</td>
                        <td className="referral-text">{data?.joining_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div> */}
                        <div className="my-earnings-table">
                          <h4 className="section-title-medium">
                          {t("referal.My_Earnings")}
                          </h4>
                          {referral?.length > 0 ? (
                            <>
                              <div className="table-responsive">
                                <table className="table dataTable cp-user-custom-table table-borderles text-center">
                                  <thead>
                                    <tr>
                                    {t("referal.Referred_By")}
                                    {t("referal.Referral_User")}
                                    {t("referal.Status")}
                                    {t("referal.Invested_Amount")}
                                    {t("referal.Rewards")}
                                    {t("referal.Date")}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {referral?.length > 0 ? (
                                      referral?.map((data, index) => (
                                        <tr key={index}>
                                          <td>
                                            {data?.from_referral_userId?.name}
                                          </td>
                                          <td>
                                            {data?.to_referral_userId?.name}
                                          </td>
                                          <td
                                            style={{
                                              color:
                                                data?.status === "investor"
                                                  ? "green"
                                                  : "red",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data?.status}
                                          </td>
                                          <td>{data?.invested_amount}</td>
                                          <td>{data?.rewarded_amount}</td>
                                          <td>{formatDate(data?.createdAt)}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <>
                                        <td
                                          colSpan={6}
                                          className="text-center referral-text m-5"
                                        >
                                          {t("referal.No_Referral_found")}
                                        </td>
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            <>
                              <td
                                colSpan={6}
                                className="d-flex justify-content-center"
                              >
                                <b>{t("referal.No_Referral_found")}</b>
                              </td>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default UserReferral;

// import { CircularProgress, Pagination } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import { useEffect, useState, useRef } from "react";
// import { toast } from "react-toastify";

// const UserReferral = () => {
//   const [referral, setReferral] = useState();
//   const [allData, setAllData] = useState();
//   const [loading, setLoading] = useState(false);
//   const { t } = useTranslation();
//   const selectReference = useRef();

//   useEffect(() => {
//     getReferral().then((res) => {
//       const code = res.data.data.url;
//       setReferral(
//           "https://exchange-dao.com/authentication/signup?" +
//           code
//       );
//       setAllData(res.data.data);
//       setLoading(false);
//     });
//     return () => {
//       setReferral(null);
//     };
//   }, []);

//   return (
//     <>
//       <div className="referral-area">
//         <div className="section-top-wrap mb-25">
//           <div className="profle-are-top">
//             {loading && <CircularProgress />}
//             <div className="container">
//               <h2 className="section-top-title">{t("Referrals")}</h2>
//             </div>
//           </div>
//         </div>
//         <div className="container mb-5">
//           <div className="invite-friends">
//             <h4>{t("Invite your friends")}</h4>
//             <div className="input-group">
//               <input
//                 ref={selectReference}
//                 onClick={() => {
//                   navigator.clipboard.writeText(referral);
//                   toast.success(t("Copied to clipboard"));
//                   selectReference.current.select();
//                 }}
//                 type="url"
//                 className="form-control referel-inputfield"
//                 id="url"
//                 defaultValue={referral}
//                 readOnly
//               />
//               <button
//                 type="button"
//                 className="btn copy-url-btn"
//                 onClick={() => {
//                   navigator.clipboard.writeText(referral);
//                   toast.success(t("Copied to clipboard"));
//                   selectReference.current.select();
//                 }}
//               >
//                 <i className="fa fa-clone" />
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="container">
//           <div className="section-wrapper rounded-sm boxShadow">
//             <div className="rewards-inviter mb-25">
//               <div className="single-item">
//                 <h4>{t("Total Rewards")}</h4>
//                 <h2>
//                   {parseFloat(allData?.total_reward).toFixed(6)} <span />
//                 </h2>
//               </div>
//               <div className="single-item">
//                 <h4>{t("Total Invited")}</h4>
//                 <h2>{allData?.count_referrals}</h2>
//               </div>
//             </div>
//             <div className="referrals-table">
//               <h4 className="section-title-medium">{t("My Referrals")}</h4>
//               <div className="table-responsive">
//                 <table
//                   className="table cp-user-custom-table table-borderless text-center dataTable no-footer"
//                   id="DataTables_Table_0"
//                 >
//                   <thead>
//                     <tr>
//                       <th
//                         className="referral-level"
//                         rowSpan={1}
//                         colSpan={1}
//                         aria-label="Level 1"
//                       >
//                         {t("Level 1")}
//                       </th>
//                       <th
//                         className="referral-level"
//                         rowSpan={1}
//                         colSpan={1}
//                         aria-label="Level 2"
//                       >
//                         {t("Level 2")}
//                       </th>
//                       <th
//                         className="referral-level"
//                         rowSpan={1}
//                         colSpan={1}
//                         aria-label="Level 3"
//                       >
//                         {t("Level 3")}
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr id="" role="row referral" className="">
//                       <td className="referral-text">
//                         {allData?.referralLevel[1]}
//                       </td>
//                       <td className="referral-text">
//                         {allData?.referralLevel[2]}
//                       </td>
//                       <td className="referral-text">
//                         {allData?.referralLevel[3]}
//                       </td>
//                     </tr>
//                     {allData?.referralLevel.length == 0 && (
//                       <td colSpan={5} className="text-center referral-text">
//                         <b>{t("No Data available")}</b>
//                       </td>
//                     )}

//                     <tr>
//                       <td colSpan={3} />
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//             <div className="referrals-table">
//               <h4 className="section-title-medium">{t("My References")}</h4>
//               <div className="table-responsive">
//                 <table className="table dataTable cp-user-custom-table table-borderless text-center">
//                   <thead>
//                     <tr>
//                       <th className="">{t("Full Name")}</th>
//                       <th className="">{t("Email")}</th>
//                       <th className="">{t("Level")}</th>
//                       <th className="">{t("Joining Date")}</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {allData?.referrals?.map((data , index) => (
//                       <tr key={index}>
//                         <td className="referral-text">{data?.full_name}</td>
//                         <td className="referral-text">{data?.email}</td>
//                         <td className="referral-text">{data?.level}</td>
//                         <td className="referral-text">{data?.joining_date}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//             <div className="my-earnings-table">
//               <h4 className="section-title-medium">{t("My Earnings")}</h4>
//               <div className="table-responsive">
//                 <table className="table dataTable cp-user-custom-table table-borderless text-center">
//                   <thead>
//                     <tr>
//                       <th>{t("Coin type")}</th>
//                       <th>{t("Amount")}</th>
//                       <th>{t("Transaction Id")}</th>
//                       <th>{t("Level")}</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {allData?.monthlyEarningHistories?.map(
//                       (data,index) => (
//                         <tr key={index}>
//                           <td>{data?.coin_type}</td>
//                           <td>{data?.amount}</td>
//                           <td>{data?.transaction_id}</td>
//                           <td>{data?.level}</td>
//                         </tr>
//                       )
//                     )}
//                     {allData?.monthlyEarningHistories.length === 0 && (
//                       <tr>
//                         <td colSpan={6} className="text-center referral-text">
//                           <b>{t("No Data available")}</b>
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     </>
//   );
// };

// export default UserReferral;
