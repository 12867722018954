import React from "react";
import appStoreIcon from "../../src/assets/images/apple-icon.png";
import playStoreIcon from "../../src/assets/images/playstore-icon.png";
import { Link } from "react-router-dom";

const SideLinks = () => {
  return (
    <div className="side-link-main">
      <Link
      target="_blank"
        to={
          "https://play.google.com/store/apps/details?id=com.jtc.fundraiserapp&pcampaignid=web_share"
        }
      >
        <div className="side-link-con">
          <p>Download</p>

          <img src={playStoreIcon} alt="play-store-icon" />
        </div>
      </Link>

      <Link
      
      target="_blank"
        to={
          "https://apps.apple.com/us/app/java-300-inversionista-jtc/id6657974667"
        }
      >
        <div className="side-link-con">
          <p>Download</p>

          <img src={appStoreIcon} alt="appStoreIcon" />
        </div>
      </Link>
    </div>
  );
};

export default SideLinks;
