import React from "react";
import { FaWhatsappSquare } from "react-icons/fa";

function WhatsappConnect() {
  return (
    <div
      className="fixed-arr"
      style={{
        position: "fixed",
        bottom: "30px", 
        right: "35px",
        zIndex: 1000
      }}
    >
      <a
        href="https://wa.me/528711236511?text=How can i help you?"
        target="_blank"
      >
        <FaWhatsappSquare color="#25D366" bgcolor="#fff" size={"60px"} />
      </a>
    </div>
  );
}

export default WhatsappConnect;
