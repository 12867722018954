import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { STATES } from "../../assets/constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddOfferForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
}) => {
  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    e.currentTarget.reset();
  };

  return (
    <Fragment>
      <Form onSubmit={submit} className="my-5">
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={6} lg={6}>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control type="text" name='type' placeholder='Offer Type' required
                                          onChange={onChangeHandler} value={formData.type}/>

            </Form.Group>
          </Col>
      
        

          <Col xs={12} md={6} lg={6}>
            <Form.Group className="mb-3" controlId="targetMinimumAmount">
              <Form.Label>Target Shares Amount</Form.Label>
              <Form.Control type="number" name='targetShares' placeholder='Target Shares Amount' required
                                          onChange={onChangeHandler} value={formData.targetShares}/>

            </Form.Group>
          </Col>


          <Col xs={12} md={6} lg={6}>
            <Form.Group className="mb-3" controlId="Reward Percentage">
              <Form.Label>Reward Percentage</Form.Label>
              <Form.Control
                type="number"
                name="rewardPercentage"
                placeholder="Reward Percentage"
                required
                onChange={onChangeHandler}
                value={formData.rewardPercentage}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <Button variant="outline-danger" type="submit">
              {heading} Offer
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AddOfferForm;
