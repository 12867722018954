import apis from "services";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { setUser } from "redux/slices/userSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Avatar, IconButton } from "@mui/material";
import utils from "utils";

import {
  PhotoCameraRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import { Col, Form, Row } from "react-bootstrap";
import imageUrl from "utils/imageUrl";

const UserProfileModal = ({ status, close }) => {
  const { language, setLanguage, t } = useTranslation();

  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const categories = [{title:'Investor',value:"investor"},{title:'JTC Employee',value:"jtc_employee"} , {title:'Friend',value:"friend"}];
  const [userData, setUserData] = useState({
    name: user?.name,
    nickName: user?.nickName,
    phone: user?.phone,
    image: "",
    category: user?.category,
  });
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (!userData) {
      setUserData({
        name: user?.name,
        nickName: user?.nickName,
        phone: user?.phone,
        image: "",
        category: user?.category || "",
      });
    }
  }, [user, userData]);


  const toggleButton = async () => {
    if (readOnly) {
      setReadOnly(false);
    } else {
      const form_data = new FormData();
      for (const [key, value] of Object.entries(userData)) {
        form_data.append(key, value);
      }

      const res = await apis.updateUserProfile(user.id, form_data);
      if (res.data.status) {
        toast.success(res.data.message);
        dispatch(setUser({ id: res.data.user?._id, ...res.data.user }));
        setReadOnly(true);
      }
    }
  };

  const onChangeHandler = (event) => {
    console.log(event.target,"event.target")
    const { id, value } = event.target;

    setUserData({ ...userData, [id]: value });
  };

  const onImageHandler = (e) =>
    setUserData((prevState) => ({ ...prevState, image: e.target.files[0] }));

  return (
    <Dialog open={status} onClose={close}>
      <DialogTitle>{t("titles.edit_profile")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" gap={2} my={2}>
          <Grid item>
            <div className="d-flex justify-content-center position-relative">
              <Avatar
                sx={{ width: "6em", height: "6em" }}
                src={
                  userData?.image
                    ? utils.createObjectURL(userData?.image)
                    : utils.serverImg(user?.image)
                }
                onError={(e) =>
                  (e.currentTarget.src = imageUrl("placeholder.png"))
                }
              />
              <IconButton
                aria-label="upload picture"
                sx={{ position: "absolute", bottom: 0, left: "52%" }}
                component="label"
              >
                <input
                  hidden
                  name="image"
                  accept="image/*"
                  type="file"
                  onChange={onImageHandler}
                />
                <PhotoCameraRounded sx={{ color: "#404040" }} />
              </IconButton>
            </div>
          </Grid>
          <Grid item>
            <Select
              id="category"
              value={userData?.category || ""}
              onChange={(e)=>setUserData((prev)=>({...prev,category:e.target.value}))}
              disabled={readOnly}
              displayEmpty
              fullWidth
            >
              <MenuItem  value="" disabled>
                {t("labels.select_category")}
              </MenuItem>
              {categories.map((cat) => (
                <MenuItem id="category" key={cat.name} value={cat.value}>
                  {cat.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item>
            <TextField
              id="name"
              value={userData?.name || ""}
              onChange={onChangeHandler}
              placeholder={t("placeholders.name")}
              label={"name"}
              disabled={readOnly}
            />
          </Grid>
          <Grid item>
            <TextField
              id="nickName"
              value={userData?.nickName || ""}
              onChange={onChangeHandler}
              label={t("labels.nick_name")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item>
            <TextField
              id="phone"
              value={userData?.phone || ""}
              onChange={onChangeHandler}
              label={t("labels.phone")}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Grid item container gap={1}>
            <Button
              onClick={toggleButton}
              variant="outlined"
              style={{ borderColor: "#E11F1C", color: "#E11F1C" }}
              fullWidth
            >
              {readOnly ? t("buttons.edit") : t("buttons.update")}
            </Button>
            <Button
              onClick={close}
              style={{ backgroundColor: "#E11F1C", color: "#ffff" }}
              fullWidth
            >
              {t("buttons.close")}
            </Button>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileModal;
