import React, {useState } from "react";
import { Fade } from "react-reveal";
import { Col } from "react-bootstrap";
import {  useSelector } from "react-redux";
import { LocationImg } from "../components/Images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../utils";
import { useTranslation } from "react-i18next";

function InvestCard(props) {
  const {
    toggleModal,
    name,
    title,
    location: { address },
    description,
    _id,
    image,
    toggleInvestmentModal,
  } = props;
  
  const { user } = useSelector((store) => store.user);

  const [more, setMore] = useState(false);

  const { t } = useTranslation();


  // const handleSubmit = () => {
  //   if (!user) {
  //   return toast.info("Register yourself first to invest",{toastId:123});
  //   } else if (user?.isRequested === true && user?.isVerified === false) {
  //     return toast.info("Your status of investment is pending");
  //   } else if (user?.isRequested === false && user?.isVerified === true) {
  //     toggleInvestmentModal();
  //   } else if (user?.isVerified) {
  //     toggleModal(_id);
  //   } else {
  //     toggleInvestmentModal();
  //   }
  // };

console.log(props,"props")
  
  const handleSubmit = () => {
    // toggleModal(_id);
    if (!user) {
    return toast.info("Register yourself first to invest",{toastId:123});
    }
    else if(!props?.active)
    {
      return toast.info("This store has funded fully",{toastId:123});   
    }
     else if (user?.isKYCCompleted === 'pending') {
      toggleInvestmentModal();
    } 
     else if (user?.isKYCCompleted === "progress" || user?.isKYCCompleted === "completed" || user?.isKYCCompleted === "signatureVerified") {
      toggleModal(_id);
    } 
  };

  return (
    <>
      <Col sm={6} lg={3} className="mt-3">
        <Fade bottom>
          <div className="invest-now-card shop-item">
            {/* <Link to={"#"} className="small-shade"> */}
              <div>
                <div className="image-section">
                  <figure>
                    <img
                      src={image}
                      alt={name}
                      onError={(e) =>
                        (e.currentTarget.src = utils._img("404.png"))
                      }
                      width={"100%"}
                      height={"150px"}
                      loading="lazy"
                    />
                    <figcaption className="row">
                      <div className="col-2">
                        <img
                          alt=""
                          src={LocationImg}
                          className="location-icon"
                          loading="lazy"
                        />
                      </div>
                      <div className="col-10">
                        <span>{address}</span>
                      </div>
                    </figcaption>
                  </figure>
                </div>
                <h4>{title}</h4>
                <p className={`invest-card-desc ${more ? "more" : "less"}`}>
                  {description}
                </p>
                <div className="show-more" onClick={() => setMore(!more)}>
                  {more ? <p>{t("buttons.show_less")}</p> : <p>{t("buttons.show_more")}</p>}
                </div>

                {
                  !props?.active ? <button onClick={handleSubmit} className="d-flex justify-content-center align-items-center mt-3 simple-square-btn d-block ">{t("buttons.funding_complete")}🎉</button> : 
                   <button
                  className="simple-square-btn d-block"
                  onClick={handleSubmit}
                >
                  
                  {t("buttons.invest_now")} 
                </button>
                }
               
              </div>
            {/* </Link> */}
          </div>
        </Fade>
      </Col>
    </>
  );
}

export default InvestCard;
